@import "../../../../layout/mixins";
@import "../../../../layout/variables";

.profile-content {
  .deposit-btn:disabled {
    filter: grayscale(100%);
  }
}

.deposit-dialog {
  .MuiDialogContent-root {
    .container-balance {
      margin-bottom: 20px;

      label {
        font-size: 20px;
        color: lighten($black, 7%);
        display: block;
        margin-bottom: 2px;
        font-weight: 500;
      }

      .block {
        display: flex;
        align-items: center;

        .icon-block {
          margin-right: $px-30;

          img {
            vertical-align: middle;
            width: 50px;
            height: auto;
          }
        }

        .amount-block {
          .amount {
            color: lighten($black, 13%);
            font-size: $px-22;
            font-weight: 700;
            line-height: 1.2;

            span {
              font-size: $px-16;
              font-weight: 400;
            }
          }

          .convertion {
            text-align: right;
            display: block;
            font-size: $px-16;
            font-weight: 400;
            line-height: 1.2;
            color: $blue;
          }
        }
      }

      @media (max-width: 670px) {
        margin-bottom: 10px;

        label {
          font-size: 14px;
        }

        .block {
          .icon-block {
            margin-right: $px-12;

            img {
              width: 30px;
              height: auto;
            }
          }

          .amount-block {
            .amount {
              font-size: $px-16;

              span {
                font-size: $px-14;
              }
            }

            .convertion {
              font-size: $px-12;
            }
          }
        }
      }
    }

    .container-textfield {
      margin-bottom: 80px;

      .MuiFormControl-root {
        width: 100%;
      }

      .MuiOutlinedInput-input {
        padding: 10px 16px;
      }

      .number-label {
        display: flex;
        align-items: center;

        label {
          line-height: 1.2;
          font-weight: 500;
        }

        button {
          margin-left: auto;
          font-size: 16px;
          min-width: 0;
          color: $blue;
          line-height: 1.2;
          padding: 4px 2px;

          &:hover {
            background-color: $white;
          }
        }
      }

      .textfield-block {
        position: relative;

        .coin-logo {
          display: none;
        }
      }

      .convertion {
        color: $blue;
        font-size: 14px;
        margin-top: 4px;
      }

      &.wallet-action {
        margin: 10px 0 16px;

        .MuiOutlinedInput-input {
          background-color: darken($white, 8%);
          font-size: 26px;
          color: $black;
          font-weight: 500;
          padding-left: 20px;
        }

        .number-label button {
          opacity: 0;
          pointer-events: none;
        }

        .textfield-block .coin-logo {
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-50%);
          display: block;
          width: 30px;
          height: auto;
        }
      }

      @media (max-width: 670px) {
        margin-bottom: 60px;

        .MuiOutlinedInput-input {
          padding: 8px 14px 7px;
          font-size: 14px;
        }

        .number-label {
          font-size: 14px;

          button {
            font-size: 12px;
          }
        }

        .convertion {
          font-size: 12px;
        }

        &.wallet-action {
          margin: 10px 0 16px;

          .MuiOutlinedInput-input {
            font-size: 20px;
            padding-left: 14px;
          }

          .textfield-block .coin-logo {
            width: 24px;
            height: auto;
          }
        }
      }
    }
  }

  .MuiDialogActions-root {
    @include custom-error;

    .button-block {
      display: flex;
      justify-content: center;
      margin-top: 8px;

      button {
        font-size: 18px;
        font-weight: 500;
        padding: 9px;
        background-color: darken($white, 12%);
        color: lighten($black, 40%);

        &[type*="submit"] {
          background-color: $blue;
          color: $white;
          position: relative;

          .text {
            font-weight: 500;
            position: relative;
            font-size: 18px;
            right: 0;
            transition: right 0.4s ease-in-out;
          }
        }

        &.Mui-disabled {
          color: $white;
          background: lighten($black, 70%);

          .text {
            right: 10px;
            opacity: 0.8;
          }
        }

        +button {
          margin-left: 8px;
        }
      }
    }

    @media (max-width: 670px) {
      .custom-error {
        .MuiAlert-message {
          font-size: 13px;
        }

        .icon {
          width: 20px;
          height: 18px;
          margin-right: $px-6;
          min-width: 20px;
        }
      }

      .button-block {
        margin-top: 8px;

        button {
          font-size: 14px;
          padding: 8px;

          &[type*="submit"] {
            .text {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

.deposit-dialog.loading-dialog {
  .MuiDialogContent-root {
    text-align: center;
    padding: 46px 80px 0;
  }

  .icon {
    width: 130px;
    height: 130px;
    margin-bottom: 44px;
    fill: $blue;
  }

  p {
    font-size: $px-20;
    margin-bottom: 14px;
  }

  .MuiDialogActions-root .button-block {
    margin-top: 0;
    margin-bottom: 10px;

    button {
      min-width: 247px;
    }
  }

  @media (max-width: 670px) {
    .MuiDialogContent-root {
      padding: 30px 38px 0;
    }

    .icon {
      width: 60px;
      height: 60px;
      margin-bottom: 30px;
    }

    p {
      font-size: $px-12;
      margin-bottom: 10px;
    }

    .MuiDialogActions-root {
      padding: 10px 30px 20px;

      .button-block {
        button {
          min-width: 148px;
        }
      }
    }
  }
}

a.option-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 11px 9px;
  margin-bottom: 14px;

  img {
    width: 20px;
  }

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin-left: 10px;
    text-align: left;

    color: #1e1e1e;
  }

  &:hover {
    background-color: transparent !important;
    border: none !important;
  }
}


.deposit-dialog.option-select .MuiPaper-root.paper-root.MuiDialog-paper.MuiDialog-paperScrollBody.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
  padding: 20px 0;
  max-width: 350px;

  .MuiDialogContent-root {
    padding: 0;
  }
}

.option-item-area-main {
  position: relative;
}

.option-item-area {
  position: absolute;
  width: 260px;
  background-color: #fff;
  top: 110%;
  z-index: 9;
  border: 1px solid #016CE9;
  box-shadow: 0px 24px 54px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  opacity: 1;
  visibility: hidden;
  a {
    border: none !important;
  }

  padding: 14px 24px 0;
  left: -70px;
}

.option-item-area.option-item-area-active {
  opacity: 1;
  visibility: visible;
}