.top-buyers-main {
  margin-top: 40px;
  margin-bottom: 40px;

  .top-buyers-boxes {
    background: #FFFFFF;
    border: 1px solid #C8CAE5 !important;
    box-shadow: 0px 17px 16px rgba(0, 0, 0, 0.04) !important;
    border-radius: 16px;
    padding: 16px;

    .top-buyers-title {
      font-family: Roboto;
      font-weight: 700;
      font-size: 24px;
      line-height: 1.2;
      letter-spacing: 0.88px;
    }

    .top-buyers-image {
      width: 48px;
      height: 48px;
      object-fit: contain;
    }

    .top-buyers-name {
      font-family: Roboto;
      font-weight: 600;
      font-size: 18px;
      line-height: 1.2;
      color: #222222;
    }

    .top-buyers-items {
      font-family: Roboto;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.2;
      color: #222222;

      .count {
        margin-left: 20px;
      }
    }
  }
}