@import "../../../layout/mixins";
@import "../../../layout/variables";

// .zera-wrapper{
//   color: #111111;
//   padding: 2rem;

//   // [theme.breakpoints.down('xs')]: {
//   //   padding: '2rem .5rem',
//   // },

//   // [theme.breakpoints.down('sm')]: {
//   //   paddingTop: '5rem',
//   // },
// }

.ace-dialog {
  p {
    font-size: 16px !important;
  }

  .contt-mid {
    margin: 30px 0px !important;
  }

  .contt-mid p {
    color: #000000 !important;
  }

  .blkbtn {
    display: flex;
    justify-content: space-between;
    margin: 47px 0px 42px 0px;

    Button {
      width: 49%;
      height: 50px;
      background-color: #ffffff;
      color: #016ce9;
      border: 2px solid #016ce9;
      font-size: 18px;
      font-weight: 500;

      &:hover {
        background-color: #016ce9;
        color: #ffffff;
      }
    }
  }

  .footer {
    background-color: #000000 !important;
  }

  .foot-main {
    display: flex;
    justify-content: space-between;
    background-color: #000000 !important;
    padding: 14px 50px;
  }
}

.participation-table {
  border-collapse: collapse;

  th {
    font-size: 20px;
    width: 14%;
    text-align: center;
    padding: 11px 0px;

    &:nth-child(3) {
      width: 20%;
    }

    &:nth-child(4) {
      width: 15%;
    }
  }

  td {
    font-size: 18px;
    color: #626262;
    width: 14%;
    text-align: center;
    padding: 11px 0px;

    img {
      margin-left: 9px;
    }

    Button {
      color: #016ce9;
      border: 1px solid #016ce9;
    }

    &:nth-child(3) {
      width: 20%;
    }

    &:nth-child(2) {
      text-align: right;
    }

    &:nth-child(4) {
      width: 15%;
    }
  }

  tr {
    border-bottom: 1px solid #dddddd;

  }
}

.whole-num-large {
  font-size: 50px;
  top: 0.25rem;
  line-height: 1;
  font-weight: bold;

  // @media (max-width: 600px) {
  //   font-size: 28px;
  // }

  @media (max-width: 600px) {
    font-size: 28px;
  }
}

.decimal-num-large {
  font-size: 30px;
  line-height: 0.8;
  opacity: 1;
  transition: opacity 0.6s;

  .hidden {
    opacity: 0;
  }

  // @media (max-width: 600px) {
  //   font-size: 22px;
  // }

  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 1;
    top: 2px;
  }
}

.whole-num {
  font-size: 30px;
  line-height: 1;
  font-weight: bold;

  // @media (max-width: 600px) {
  //   font-size: 22px;
  // }

  @media (max-width: 600px) {
    // font-size: 4vw;
    font-size: 16px;
    line-height: 1;
  }
}

.decimal-num {
  font-size: 18px;
  line-height: 1;
  opacity: 1;
  transition: opacity 0.6s;

  .hidden {
    opacity: 0;
  }

  // @media (max-width: 600px) {
  //   font-size: 16px;
  // }

  @media (max-width: 600px) {
    font-size: 10px;
  }
}

.MuiTooltip-popper {
  .MuiTooltip-tooltip {
    text-align: left;
    margin: 0 4px;
    padding: 0;
    background: none;

    .tooltip-content {
      padding: 18px 18px;
      background-color: $white;
      box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
      color: $text-shade-3;
      border-radius: 4px;
      display: inline-block;
    }

    h5 {
      margin: 0;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 10px;
    }

    p {
      font-size: 14px;
      font-weight: 300;
      line-height: 1.4;
      margin-top: 2px;
      color: #111111;
    }

    .MuiTooltip-arrow {
      color: $white;
    }
  }
}

#edao-gov-wrapper,
#edao-wrapper {
  .MuiGrid-container {
    &[justifycontent="flex-end"] {
      justify-content: flex-end;
    }
  }

  .buttons-block {
    display: flex;

    .pledge-btn {
      width: 100%;
    }

    button:not(:last-child) {
      margin-right: 7px;
    }
  }

  .token-details.earned-balance {
    .amount {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  .amount {
    &>* {
      display: inline-block;
    }

    >.whole-num:last-of-type {
      margin-right: 10px;
    }

    button {
      text-transform: none;
      font-size: 16px;

      @media (max-width: 600px) {
        font-size: 14px;
      }
    }

    .convertion {
      margin-left: 0;
      font-weight: 500;
      font-size: 12px;
      color: #A8A8A8;
      white-space: nowrap;
    }
  }

  .info-btn {
    padding: 5px;
    position: relative;
    top: -1px;

    svg {
      width: 18px;
      height: 18px;
      fill: darken($white, 30%)
    }

    &.open svg {
      fill: $blue;
    }
  }
}

.edao-modal {
  .paper-root {
    max-width: 900px;
  }

  .container-check {
    width: auto !important;
  }

  .MuiTooltip-tooltip {
    .tooltip-content {
      max-width: unset;
      width: auto !important;

      p {
        white-space: nowrap;
      }

      ;
    }
  }
}