@import "../../layout/mixins";
@import "../../layout/variables";

.home-content{
  background-color: $white;
  padding-bottom: 5rem;

  @media (max-width: 600px) {
    padding-bottom: 3rem;
  }

  .section{
    padding: $px-20 0;

    .section-title{
      font-size: $px-24;
      font-weight: $font-weight-bb;
      text-transform: uppercase;
      color: $black;
      margin-bottom: 1.3rem;
      text-align: left;
    }

    @media (max-width: 600px) {
      padding: $px-14 0;

      .section-title{
        font-size: $px-20;
        margin-bottom: $px-12;
      }
    }
  }

  .section-ticker{
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);

    &.section{
      padding: 0;
    }

    .ticker-content {
      font-size: $px-14;
      padding: $px-8 0;

      .fade-container{
        display: inline-block;
        width: calc( 100% - 60px);
        height: 20px;
      }

      .ticker{
        display: inline-block;
        width: 100%;
        max-height: 20px;
        top: 1px;

        &:after,
        &:before{
          content: '';
          position: absolute;
          height: 100%;
          width: 10px;
          z-index: 2;
        }

        &:after{
          left: 0;
          background-image: linear-gradient(to right, rgba($white, .9) , rgba($white, 0));
        }
        &:before{
          right: 0;
          background-image: linear-gradient(to right, rgba($white, 0) , rgba($white, .9));
        }

        .ticker__element{
          white-space: nowrap;
          max-height: 20px;
          padding-right: 50px;
        }
      }

      &.market-ticker{
        background: lighten($black, 97%);

        .ticker{
          &:after{
            background-image: linear-gradient(to right, rgba(lighten($black, 97%), .9) , rgba(lighten($black, 97%), 0));
          }
          &:before{
            background-image: linear-gradient(to right, rgba(lighten($black, 97%), 0) , rgba(lighten($black, 97%), .9));
          }

          .market-list{
            display: flex;
            padding: 0;
            margin: 0;
            list-style: none;
            padding: 0 5px;
            margin: 0 5px;
            
            > li{
              display: flex;
              margin: 0;
              font-size: $px-12;
              color: darken($text-shade-3, 30%);
              margin-right: $px-24;
              align-items: center;

              img{
                display: inline-block;
                height: 18px;
                margin-right: 4px;

                @media (max-width: 600px) {
                  height: 16px;
                }
              }

              label{
                font-weight: 600;
                display: inline-block;
                margin-right: 4px;

                .icon-gas{
                  width: 11px;
                  top: 1px;
                  position: relative;
                  fill: darken($text-shade-3, 30%);
                  margin-right: 3px;

                  @media (max-width: 600px) {
                    width: 9px;
                  }
                }
              }

              span{
                display: inline-block;
                margin-right: 4px;

                &:last-child{
                  margin-right: 0;
                }

                &.blue{
                  color: darken($sky-blue-2, 5%);
                  font-weight: 300;
                }

                &.percentage{
                  padding-right: 10px;
                  position: relative;

                  &.down{
                    color: $red;
                    .icon-down{
                      display: block;
                      fill: $red;
                      top: 1px;
                    }
                  }

                  &.up{
                    color: $avocado;
                    .icon-up{
                      display: block;
                      fill: $avocado;
                    }
                  }

                  svg{
                    width: 6px;
                    top: 2px;
                    right: 2px;
                    position: absolute;
                    display: none;
                    @media (max-width: 600px) {
                      width: 5px;
                      top: 1px;
                    }
                  }
                }

                &.abbrev{
                  margin-right: 8px;
                  font-weight: 300;
                }

                &.price{
                  font-weight: 500;

                  &.down{
                    color: $red;
                  }

                  &.up{
                    color: $avocado;
                  }

                  .percentage{
                    font-weight: 300;
                    margin-left: 6px;
                    padding-right: 16px;

                    &.down{
                      .icon-down{
                        top: -5px;
                      }
                    }

                    svg{
                      width: 20px;
                      top: -4px;
                      right: -4px;

                      @media (max-width: 600px) {
                        width: 18px;
                        top: -6px;
                        right: -2px;
                      }
                    }
                  }
                }
              }
            }
          }

          .ticker__element{
            padding-right: 100px;
          }
        }
      }

      &.news-ticker{
        background: $white;

        .ticker{
          .news-link{
            max-width: 360px;
            padding: 0 5px;
            margin: 0 5px;
            vertical-align: middle;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            font-size: $px-12;
            color: darken($text-shade-3, 10%);
            text-decoration: none;
  
            .icon-text{
              color: $red;
            }
          }
        }
      }

      .MuiContainer-root{
        display: flex;
        align-items: center;
      }

      .category{
        display: inline-block;
        font-weight: 700;
        margin-right: $px-8;
        min-width: $px-50;

        a{
          font-weight: 700;
          text-decoration: none;
          color: inherit;
        }
      }

      .no-data{
        font-size: $px-12;
        color: darken($text-shade-3, 5%);
        margin-left: $px-10;
      }

      @media (max-width: 600px) {
        font-size: $px-12;

        &.market-ticker{
          .ticker .market-list > li{
            font-size: $px-10;
          }
        }

        &.news-ticker .ticker .news-link{
          font-size: $px-10;
        }

        .category{
          margin-right: 0;
        }

        .no-data{
          font-size: $px-10;
        }
      }
    }
  }

  .section-analytics{
    .box{
      border-radius: $px-10;
      border: 1px solid rgba(0, 0, 0, 0.12);
      background: $white;
      height: 100%;
      padding: $px-40 $px-20 $px-20;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      width: 100%;

      h4{
        margin-bottom: $px-8;
        font-size: $px-40;
        text-align: center;
        @include ellipsis;
      }

      .subtext{
        margin: 0 0 $px-10;
        font-size: $px-18;
        text-transform: uppercase;
        text-align: center;
        @include ellipsis;
      }

      .muted-text,
      .muted-text a{
        margin: 0;
        color: $text-shade-5;
        font-size: $px-16;
        text-align: center;
        @include ellipsis;
      }

      @media (max-width: 959px){
        justify-content: center;
      }

      @media (max-width: 899px){
        padding: $px-16 $px-20 .1rem;
        h4{
          font-weight: $font-weight-b;
          font-size: $px-24;
        }
        .muted-text,
        .subtext{
          font-size: $px-14;
        }
      }
    }
  }

  .section-recommended{
    .box{
      text-align: center;

      .MuiButtonBase-root{
        display: block;
        border-radius: $px-10;
      }
    }
    .img-container{
      border: 1px solid rgba(0, 0, 0, 0.12);
      border-radius: $px-10;
      overflow: hidden;
      position: relative;
      img{
        vertical-align: middle;
      }
      .hover-video-container{
        position: absolute;
        top: 0;
        left: 0;
        .hover-video-player, video{
          height: 100%;
          width: 100%;
        }
      }
    }
    .details{
      @include ellipsis;
      h4{
        font-size: $px-20;
        margin: .5rem 0 .2rem;
        line-height: 1.2;
        @include ellipsis;
      }
      .currency{
        margin: 0;
        font-size: $px-16;
        line-height: 1.2;
      }
    }
    @media (max-width: 1259px) and (min-width: 960px) {
      .details{
        h4{
          font-size: $px-18;
        }
      }
    }
    @media (max-width: 959px) {
      .details{
        text-align: left;
      }
    }
    @media (max-width: 600px) {
      .box{
        .MuiButtonBase-root{
          border-radius: 1.3vw;
        }
      }
      .img-container{
        border-radius: 1.3vw;
      }
      .details{
        font-size: $px-10;
        h4{
          font-size: $px-12;
        }
        .currency{
          font-size: $px-10;
        }
      }
    }
  }

  .section-weekly-top{
    text-align: center;

    .MuiButtonBase-root{
      display: block;
    }

    .box{
      padding: 0 .7rem .7rem .2rem;
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      margin-bottom: .7rem;
      text-align: left;
    }
    .img-container{
      display: flex;
      align-items: center;
      margin-right: $px-20;
      .rank{
        display: inline-block;
        font-size: $px-40;
        margin-right: $px-30;
        color: $text-shade-5;
        font-weight: $font-weight-m;
      }
      img{
        vertical-align: middle;
        max-width: 100px;
        border: 1px solid rgba(0, 0, 0, 0.12);
        border-radius: $px-10;
        overflow: hidden;
      }
    }
    .details{
      @include ellipsis;
      h4{
        font-size: $px-20;
        margin: 0 0 .2rem;
        @include ellipsis;

        @media (min-width:960px) and (max-width: 1259px) {
          font-size: $px-18;
        }
      }
      .currency{
        text-align: left;
        margin: 0;
      }
    }
    .view-more--btn{
      margin: $px-16 auto 0;
      border-radius: 999px;
      border-color: #e6e6e6;
      line-height: 1;
      color: $text-shade-5;
      font-weight: 500;
      text-transform: unset;
      padding: $px-16 $px-30;
      font-size: $px-18;
    }
    @media (max-width: 959px){
      .MuiGrid-item{
        padding-top: 0;
        padding-bottom: 0;

        &:first-child{
          padding-top: 12px;
        }
        &:last-child{
          padding-bottom: 12px;
        }
      }
    }
    @media (max-width:1259px) and (min-width:960px){
      .img-container{
        .rank{
          font-size: $px-30;
          margin-right: $px-20;
        }
      }
    }
    @media (max-width:600px){
      .box{
        padding: 0 .6rem .6rem .2rem;
        margin-bottom: .6rem;
      }
      .img-container {
        margin-right: $px-12;
        .rank{
          margin-right: $px-12;
          font-size: $px-20;
        }

        img{
          max-width: 60px;
          border-radius: 1.3vw;
        }
      }
      .details {
        h4{
          font-size: $px-12;
        }

        .currency{
          font-size: $px-10;
        }
      }
      .view-more--btn{
        margin: $px-8 auto 0;
        font-size: $px-12;
        padding: $px-10 $px-20;
      }
    }
  }

  .section-top-chart{
    .dapparcade-btn-sat{
      text-align: center;
      margin-bottom: $px-22;
      .MuiButtonBase-root{
        display: inline-block;
        margin: 0 auto;
        width: auto;
        border-radius: 999px;
        padding: $px-10 $px-40;
        font-size: $px-18;
      }
    }

    .MuiButtonBase-root{
      display: block;
      height: 100%;
      width: 100%;
      border-radius: $px-10;
    }
    .box{
      border-radius: $px-10;
      height: 100%;
      width: 100%;
      padding: $px-30;
      min-height: 180px;
      text-align: left;
      position: relative;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      &.box-game{
        background-image: url('../../assets/images/section-whats-hot/banner-game.jpg');
      }
      &.box-nft{
        background-image: url('../../assets/images/section-whats-hot/banner-nft.jpg');
      }
      &.box-defi{
        background-image: url('../../assets/images/section-whats-hot/ai-banner.png');
      }
      &.box-exchange{
        background-image: url('../../assets/images/section-whats-hot/banner-exchange.jpg');
      }

      @media (max-width: 1100px) and (min-width: 960px) {
        background-size: contain;
        min-height: 14vw;
      }

      @media (max-width: 959px) {
        min-height: 28vw;

        &.box-game{
          background-image: url('../../assets/images/section-whats-hot/mobile/banner-game.png');
        }
        &.box-nft{
          background-image: url('../../assets/images/section-whats-hot/mobile/banner-nft.png');
        }
        &.box-defi{
          // background-image: url('../../assets/images/section-whats-hot/mobile/banner-defi.png');
          background-image: url('../../assets/images/section-whats-hot/ai-banner.png');
          
        }
        &.box-exchange{
          background-image: url('../../assets/images/section-whats-hot/mobile/banner-exchange.png');
        }
      }
    }

    @media (max-width: 600px) {
      .MuiButtonBase-root{
        border-radius: $px-10;
      }
      .box{
        border-radius: 1.3vw;
        padding: $px-26;
      }
    }
  }

  .section-new{
    .MuiButtonBase-root{
      border-radius: $px-10;
      width: 100%;
    }
    .grid-container{
      width: 100%;
    }
    .paper{
      padding: $px-16 $px-16;
      border-radius: $px-10;
      width: 100%;

      .right-block{
        display: flex;
        align-items: center;
        margin-right: 5px;

        .img-container{
          width: 78px;
          height: 78px;
          border-radius: $px-10;
          border: 1px solid rgba(0, 0, 0, 0.12);
          overflow: hidden;
    
          img{
            margin: auto;
            display: block;
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
      
      .left-block{
        justify-content: center;
        > div{
          width: 100%;
        }
        h4{
          margin: 0;
          font-size: $px-20;
          @include ellipsis;
          span{
            @include ellipsis;
          }
        }
        p{
          margin: 0;
          
          &.currency{
            font-size: $px-14;
            color: $text-shade-3;
          }
        }
        .text p{
          font-size: $px-14;
          color: $text-shade-4;
          overflow: hidden;
        }
        .line-clamp p{
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;  
        }

        @media (min-width:960px) and (max-width: 1259px) {
          width: 50%;
          h4{
            font-size: $px-18;
          }
        }
      }
    }

    .comming-soon{
      display: flex;
      flex-direction: column;
      .img-container{
        display: block;
        height: 100%;
        .hover-video-player{
          border-radius: $px-10;
          overflow: hidden;
          max-width: 393px;
          height: 100%;
          video,
          img{
            width: 100%;
            height: 100%;
            vertical-align: middle;
          }
        }
      }
      @media (max-width: 959px) {
        padding-top: $px-20;
        width: 100%;
        display: block;
        .img-container{
          display: block;
          height: auto;
          width: 100%;

          .hover-video-player{
            max-width: 100%;
          }
        }
      }
    }

    @media (max-width: 600px) {
      .MuiButtonBase-root{
        border-radius: 1.3vw;
      }
      .paper{
        padding: $px-10;
        border-radius: 1.3vw;

        .right-block{
          margin-right: 2px;
  
          .img-container{
            width: 60px;
            height: 60px;
            border-radius: 1.3vw;
          }
        }
        
        .left-block{
          h4{
            font-size: $px-12;
          }
          p.currency{
            font-size: $px-10;
          }
          .text p{
            font-size: $px-10;
          }
        }
      }
    }
  }

  .section-media{
    .media-card-slide{
      height: 100%;
      &:focus{
        outline: none;
      }
    }
    .media-card{
      border-radius: $px-10;
      height: 100%;
      outline: none;
      h4{
        font-size: $px-20;
        font-weight: $font-weight-b;
      }
      .date{
        margin: 0;
        color: $text-shade-3;
      }
      .MuiCardMedia-root{
        height: 215px;
        @media (min-width:500px) and (max-width:959px){
          height: 330px;
        }
      }
      .MuiCardContent-root{
        padding-bottom: $px-10;
      }
      .MuiCardActions-root{
        padding: $px-10 $px-16 $px-12;
      }
    }

    @media (max-width: 600px) {
      .card{
        border-radius: 1.3vw;
        h4{
          font-size: $px-16;
        }
        .date{
          font-size: $px-12;
        }
        .MuiCardContent-root{
          padding: $px-18;
          padding-bottom: $px-8;
        }
        .MuiCardActions-root{
          padding: $px-8 $px-18 $px-12;
        }
      }

      .slick-dots li{
        margin: 0;

        button{
          padding: 3px;
        }
      }

      .slick-dots li,
      .slick-dots li button,
      .slick-dots li button:before{
        width: 16px;
        height: 16px;
      }

      .slick-dots li button:before{
        line-height: 18px;
      }
    }
  }
}

.full-container {
  max-width: 95% !important;
  padding: 0 !important;
}