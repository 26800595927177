.nav-btn {
  position: absolute;
  transform: translateX(38px);
  top: 50%;
  right: 0;
  width: 40px;
  height: 60px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f6f6f6;
  outline: none;
  border: none;
  font-size: 1rem;
  padding: 5px 10px;
  cursor: pointer;
  color: #fff;
}

.wallet-item-container {
  display: flex;
  align-items: center !important;
}
.sidebar-loader{
  color: #0060ff !important;
}

.empty-cart{
  
  padding: 30px 20px;
}
.verify-box {
  box-sizing: border-box;
  // position: absolute;
  width: 100%;
  height: 194px;
  // left: calc(50% - 327px/2);
  // bottom: 342px;
  background: #f5f5f5;
  border-radius: 8px;
  padding: 25px;
}

.verify-header {
  display: flex;
  gap: 15px;
  margin-bottom: 15px;

  &__text {
    width: 132px;
    height: 21px;
    left: calc(50% - 132px / 2 - 37.5px);
    bottom: 450px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #1e1e1e;
  }
}

.verify-body {
  width: 100%;
  height: 42px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #4f4f4f;
  margin-bottom: 25px;
  text-align: left;
}

.verify-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 10px;
  width: 100%;
  height: 40px;
  background: #0060ff;
  border-radius: 16px;
  border-color: #ffffff;
  color: #ffffff;
  box-shadow: none;
  border: none;
  font-size: 14px;
  font-weight: 700;
}

.history-item-area {
  height: auto;
  overflow-y: auto;
}

.referral-container {
  display: flex;
  flex-direction: column;
  // position: absolute;
  width: 100%;
  //   height: 80px;
  // left: calc(37.5% - 227px/2);
  // bottom: 142px;
  border-radius: 8px;

  // padding: 25px;
  p {
    font-size: 14px;
  }
}

.inputWithButton {
  position: relative;
  height: 100px;
  width: 200px;
}

.inputWithButton input {
  width: 70%;
  height: 25px;
  padding-right: 80px;
}

.inputWithButton button {
  position: absolute;
  right: -20;
  top: 5px;
}

.referral-container {
  margin-top: 20px;
}

.referral-input-container {
  display: flex;
  align-items: center !important;
  background: #f1f1f1;
  border-radius: 16px;
  position: relative;
}

.referral-input {
  width: 167px;
  height: 48px;
  left: calc(50% - 327px / 2);
  bottom: 208px;
  background: #f1f1f1;
  border-radius: 16px;
  border: none;
}

.referral-button {
  width: 110px;
  height: 38px;
  right: 4px;
  bottom: 5px;
  background: #0060ff;
  border-radius: 16px;
  color: #ffffff;
  border: none;
  position: absolute;
  font-size: 14px;
  font-weight: 700;
}

.sidebar-links {
  // position: absolute;
  width: 327px;
  height: 194px;
  // left: calc(50% - 327px/2);
  // bottom: 32px;

  &__icons {
    margin-right: 15px;
    width: 24px;
    height: 24px;
  }

  &__links {
    margin: 25px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #1e1e1e;
  }

  &__href {
    color: #1e1e1e;
    text-decoration: none;
  }
}

.disconnect-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 10px;
  // position: absolute;
  width: 100%;
  height: 40px;
  // left: calc(50% - 327px/2);
  // bottom: 24px;
  background: #f4f4f4;
  border-radius: 16px;
  border: none;
  margin-top: 5px;
}

.qr-container {
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
  gap: 10px;

  svg {
    width: 18px;

    path {
      fill: #016ce9;
    }
  }

  .MuiIconButton-root {
    padding: 2px;
  }
}

.qr_diag_btn {
  color: #0060ff !important;
  cursor: pointer;

  span {
    margin-right: 15px;
  }
}

.history-drawer.update-sidebar {
  width: 350px;
  text-align: center;
  border-left: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}

.referral-button.copy-btn {
  position: inherit;
}

.qr-container {
  p {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #1e1e1e;
  }
}

.dappx-custom {
  margin-right: 12px;
}

.history-drawer.update-sidebar.paypal-sitebar {
  .qr-container {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 29px;
    /* identical to box height */

    text-align: center;

    color: #464646;
  }
}

.cart-item-title {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #464646;
}

.cart-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  justify-content: space-between;
  padding: 16px 0;
 

  &:last-child {
    border-bottom: none;
  }



  >img {
    width: 30px;
  }

  p {
    font-style: normal;
    font-size: 14px;
    line-height: 19px;
 
    color: #464646;
  }
  &:first-child {
    p{
      font-weight: 600;
    }
  }

  span {
    font-style: normal;
    // font-weight: 600;
    font-size: 14px;
    line-height: 17px;

    color: #464646;
  }

  .pricing {
    background: #f2f2f2;
    border-radius: 16px;
    display: flex;
    align-items: center;
    padding: 6px 5px;
    p {
      width: 19px;
    }
    button {
      background: transparent;
      border: none;
      padding: 0 10px;

      img {
        width: 12px;
      }
    }
  }

  >button {
    background: transparent;
    border: none;

    img {
      width: 20px;
    }
  }
}


button.disconnect-button.paypal {
  background: #0060FF;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
}

.total-price-list {
  ul {
    list-style: none;
    padding: 0;
    margin: 0 0 0 0;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0 15px 0;
      margin: 0;

      p {
        font-weight: 700;
      }

      span {}
    }

    li.total-price {
      border-top: 1px solid #e0e0e0;

      p {
        font-weight: 700;
        font-size: 18px;
      }

      span {
        font-weight: 700;
        font-size: 18px;
      }
    }
  }
}

.share-area {
  .option-item-area {
    width: 100px;
    left: inherit;
    right: 7px;
    padding: 14px 10px 0;
  }

  button.option-item {
    border: none;
    background: transparent;
    margin-bottom: 14px;
  }
}