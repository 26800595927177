@import "../../layout/mixins";
@import "../../layout/variables";

.top-charts-content{
  background-color: $white;

  .section{
    padding: $px-40 0;

    .section-title{
      font-size: $px-34;
      font-weight: $font-weight-bb;
      color: $black;
      margin-bottom: $px-30;
      text-align: left;

      @media (max-width: 600px) {
        font-size: $px-28;
        margin-bottom: $px-24;
      }
    }
  }

  .MuiButtonBase-root{
    display: block;
    height: 100%;
    width: 100%;
    border-radius: $px-10;
  }

  img{
    width: 100%;
    height: 100%;
    vertical-align: middle;
  }

  .section-charts{
    padding: $px-60 0;
    .img-container{
      border-radius: $px-10;
      border: 1px solid rgba(0, 0, 0, 0.12);
      height: 100%;
      width: 100%;
      overflow: hidden;
      position: relative;

      img{
        vertical-align: middle;
        height: 100%;
      }
      .hover-video-container{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
  
        .hover-video-player, img{
          width: 100%;
          height: 100%;
        }
  
        video{
          height: 100%;
        }
      }
    }
  }

  .section-other-charts{
    background-color: #f6f8fc;
    padding-bottom: $px-60;

    .section-title{
      margin-bottom: $px-22;
    }

    .box{
      border-radius: $px-10;
      height: 100%;
      width: 100%;
      padding: $px-30;
      min-height: 200px;
      text-align: left;
      position: relative;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      &.box-game{
        background-image: url('../../assets/images/section-whats-hot/others/banner-game.jpg');
      }
      &.box-nft{
        background-image: url('../../assets/images/section-whats-hot/others/banner-nft.jpg');
      }
      &.box-defi{
        background-image: url('../../assets/images/section-whats-hot/others/banner-defi.jpg');
      }
      &.box-exchange{
        background-image: url('../../assets/images/section-whats-hot/others/banner-exchange.jpg');
      }
      
      @media (max-width: 959px) {
        min-height: 24vw;
        &.box-game{
          background-image: url('../../assets/images/section-whats-hot/others/mobile/banner-game.jpg');
        }
        &.box-nft{
          background-image: url('../../assets/images/section-whats-hot/others/mobile/banner-nft.jpg');
        }
        &.box-defi{
          background-image: url('../../assets/images/section-whats-hot/others/mobile/banner-defi.jpg');
        }
        &.box-exchange{
          background-image: url('../../assets/images/section-whats-hot/others/mobile/banner-exchange.jpg');
        }
      }

      @media (max-width: 1100px) and (min-width: 960px) {
        background-size: contain;
        min-height: 15.7vw;
      }

      @media (max-width: 600px) {
        padding: $px-26;
      }
    }
  }

  @media (max-width: 1279px) {
    .section-other-charts{
      background-color: $white;
    }
  }

  @media (max-width: 959px) {
    .section{
      padding: $px-30 0;
    }

    .section-charts{
      padding-top: $px-34;
    }

    .section-other-charts{
      padding-bottom: $px-50;
    }
  }

  @media (max-width: 600px) {
    .MuiGrid-container{
      &.MuiGrid-spacing-xs-3{
        width: calc(100% + 16px);
        margin: -8px;
      }

      &.MuiGrid-spacing-xs-3 > .MuiGrid-item{
        padding: 8px;
      }
    }
  }
}