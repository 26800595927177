.button-component {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 53px;
  border-radius: 8px;
  font-family: Roboto;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: 0.35px;
  text-align: left;
  color: #fff;
}