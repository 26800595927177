.nft-collection-area,
.collections-area {
    margin-top: 40px;
    margin-bottom: 40px;

    .items {
        .card {
            height: 100%;
            background: #FFFFFF;
            border: 1px solid #C8CAE5 !important;
            box-shadow: 0px 17px 16px rgba(0, 0, 0, 0.04) !important;
            border-radius: 16px;
            padding: 16px;
            cursor: pointer;

            .card-body {
                h5 {
                    font-weight: 600;
                    font-size: 14px;
                }
            }

            .image-over {
                overflow: unset;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .card-caption {
                height: fit-content;
            }
        }
    }
}

.collections-area {
    .items {
        .image-over {
            overflow: unset;
            background: #FFFFFF;
            border-radius: 6px;
        }
    }
}

.nft-collection-area {
    .intro {
        .intro-btn {
            .back-btn {
                padding-left: 25px;

                &::before {
                    -webkit-transform: scaleX(-1);
                    transform: scaleX(-1);
                    top: 0;
                    left: 10px;
                    right: unset;
                }

                &::after {
                    -webkit-transform: scaleX(-1);
                    transform: scaleX(-1);
                    top: 0;
                    left: 0;
                    right: unset;
                }

                &:hover {
                    &::before {
                        left: 0;
                        right: unset;
                    }

                    &::after {
                        left: 10px;
                        right: unset;
                    }
                }
            }
        }
    }

    .items {
        .card {
            background: transparent;
            border: 0 !important;
            padding: 0;
            box-shadow: none !important;

            .card-body {
                h5 {
                    font-weight: 600;
                    font-size: 16px;
                    margin-bottom: 8px;
                }

                p {
                    font-weight: 400;
                    font-size: 12px;
                    margin-bottom: 8px;
                    display: flex;
                    align-items: center;

                    svg {
                        height: 20px;
                    }
                }
            }

            .image-over {
                min-height: 165px;
                background: #FFFFFF;
                border-radius: 6px;

                .availablity {
                    position: absolute;
                    top: 8px;
                    left: 8px;
                    font-weight: 600;
                    font-size: 12px;
                    color: #FAFAFA;
                    background: #4FB42B;
                    border-radius: 100px;
                    padding: 4px 16px;
                    text-transform: uppercase;

                    &.sold {
                        background-color: #545454;
                    }
                }
            }
        }
    }

    .pagination {
        display: flex;
        justify-content: center;
        margin: 40px 0px 18px;

        button {
            &.Mui-selected {
                background-color: #016CE9 !important;
                border-color: #016CE9;
                color: #fff;

                &:hover {
                    color: #fff !important;
                }
            }

            &:hover {
                color: #016CE9 !important;
                background-color: #fff;
                border-color: #016CE9 !important;
            }
        }

        ul {
            display: flex;
            justify-content: center;

            li {
                button {
                    border-radius: 8px;
                    font-size: 12px !important;
                    border-color: #A6A6A6;
                    color: #A6A6A6;
                }
            }
        }
    }
}

.buy-nft-dialog {
    .paper-root {
        padding: 24px;

        .MuiDialogContent-root,
        .MuiDialogActions-root {
            padding: 0 !important;
        }

        .MuiDialogActions-root {
            .button-block {
                .imx-button-container {
                    width: 100%;

                    button {
                        width: 100%;
                    }
                }

                // &.imx-connected {
                //     .imx-button-container {
                //         display: none;
                //     }
                // }

                // &.imx-not-connected {
                //     .mint-button {
                //         display: none;
                //     }
                // }
            }
        }

        .card {
            .label {
                font-weight: 600;
                margin-bottom: 8px;
                font-size: 14px;
            }

            .image-over {
                width: 200px;
                height: 200px;

                .availablity {
                    position: absolute;
                    top: 8px;
                    left: 8px;
                    font-weight: 600;
                    font-size: 12px;
                    color: #FAFAFA;
                    background: #4FB42B;
                    border-radius: 100px;
                    padding: 4px 16px;
                    text-transform: uppercase;

                    &.sold {
                        background-color: #545454;
                    }
                }
            }

            .card-caption {
                flex-basis: 320px;
                margin-left: 30px;

                .card-body {
                    padding: 0;

                    .nft-name {
                        font-weight: 600;
                        font-size: 18px;
                    }

                    .collection-name {
                        font-weight: 400;
                        font-size: 14px;
                        margin-bottom: 8px;
                    }

                    .nft-price {
                        font-weight: 600;
                        font-size: 14px;
                        display: flex;
                        align-items: center;
                        margin-bottom: 16px;

                        svg {
                            margin-left: 5px;
                            height: 24px;
                            width: 24px;
                        }
                    }

                    .nft-description {
                        height: 65px;
                        overflow-y: auto;
                        margin-right: -20px;
                        padding-right: 5px;

                        p {
                            font-size: 14px;
                        }
                    }
                }
            }

            .properties-container {
                max-height: 130px;
                overflow-y: scroll;
                margin-right: -15px;
                padding-right: 5px;

                div {
                    span {
                        font-weight: 400;
                        font-size: 14px;

                        &:last-child {
                            font-weight: 600;
                        }
                    }
                }
            }

            /* width */
            ::-webkit-scrollbar {
                width: 10px;
            }

            /* Track */
            ::-webkit-scrollbar-track {
                background: transparent;
            }

            /* Handle */
            ::-webkit-scrollbar-thumb {
                background: #D9D9D9;
                border-radius: 10px;
            }

            /* Handle on hover */
            ::-webkit-scrollbar-thumb:hover {
                background: #808080;
            }
        }
    }
}

.no-collections-container {
    display: flex;
    justify-content: center;
}

.learn-more {
    width: 121px;
    height: 35px;
    background-color: #016CE9;
    border-radius: 5px;
    color: #FFFFFF;
    border-color: #016CE9;
}

.mobile-modal-conatainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mobile-modal-header {
    margin: 10px 10px 0 10px; 
    width:24.5rem;
    height: 82px; 
    top: 139px; 
    left: 27px; 
    font-family: roboto; 
    font-weight: 600;
    font-size: 18px; 
    text-align: center
}

.mobile-modal-body {
    width:24rem;
    height:105px; 
    top: 239px; 
    left: 27px; 
    font-family: roboto; 
    font-weight: 400; 
    font-size: 18px; 
    text-align: center; 
    text-justify: inner-word
}

.new-banner-wrapper {
    position: relative;
    a {

        position: absolute;
        bottom: 1%;
        left: 50%;
        transform: translateX(-50%);
        background: transparent;
        border: none;
    }
}

@media screen and (min-width:481px) and (max-width:820px){
    .mobile-marketplace {
        background-color: #102844;
        border-top: 1px solid #E7E7E7;
        text-align: center;
        padding: 20px;
        position: fixed;
        left: 0;
        bottom: 0;
        height: 6rem;
        width: 100%;
        display: flex;
        color: white;
        justify-content: space-between;
        align-items: center;
        z-index: 1;
    
        .__text {
            display: flex;
            justify-content: center;
        }
    }
    
    .mobile-modal-conatainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 345px !important;
        width: 328px !important;
        left: 266px;
        top: 454px;
        border-radius: 10px;
        // position: absolute;
    }

    .mobile-modal-logo {
        position: absolute;
        width: 80px;
        height: 80px; 
        left: calc(50% - 80px/2); 
        top: 49px;
    }

    .mobile-modal-header {
        margin: 10px 10px 0 10px; 
        height: 54px;
        width: 362px;
        left: calc(50% - 362px/2);
        top: 165px;
        border-radius: nullpx;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        text-align: center;
        position: absolute;
    }

    .mobile-modal-body {
        position: absolute;
        width: 362px;
        height: 84px;
        left: calc(50% - 362px/2);
        top: 243px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        text-align: center; 
    }

    .mobile-modal-button {
        position: absolute;
        width: 247px;
        height: 50px;
        left: calc(50% - 247px/2 - 0.5px);
        top: 351px;  
        background: #016CE9;
        border-radius: 5px;
        color: #FFFFFF;
    }
 }

 @media screen and (min-width:320px) and (max-width:480px){
    .new-banner-wrapper {
        a {
            bottom: -1%;
        }
    }
    .mobile-marketplace {
        background-color: #102844;
        border-top: 1px solid #E7E7E7;
        text-align: center;
        padding: 20px;
        position: fixed;
        left: 0;
        bottom: 0;
        height: 115px;
        width: 100%;
        display: flex;
        flex-direction: column;
        color: white;
        justify-content: center;
        align-items: center;
        z-index: 1;
        gap: 7px;
    
        .__text {
            display: flex;
            align-items: center;
            text-align: initial;
        }
    }

    .mobile-modal-conatainer {
        position: absolute;
        width: 288px;
        height: 81px;
        left: calc(50% - 288px/2);
        top: 139px;
        text-align: center;
        
    }

    .mobile-modal-logo {
        position: absolute;
        width: 80px;
        height: 80px;
        left: calc(50% - 80px/2);
        top: -95px;
    }

    .mobile-modal-header {
        position: absolute;
        width: 288px;
        height: 81px;
        left: calc(50% - 288px/2);
        top: 0px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        text-align: center;
    }

    .mobile-modal-body {
        position: absolute;
        width: 298px;
        height: 105px;
        left: calc(50% - 288px/2);
        top: 105px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        text-align: center;
    }

    .mobile-modal-button {
        position: absolute;
        width: 247px;
        height: 50px;
        left: calc(50% - 247px/2 - 0.5px);
        top: 379px;
        background: #016CE9;
        border-radius: 5px;
        color: #FFFFFF;
    }
 }