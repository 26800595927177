@import "../../../../layout/mixins";
@import "../../../../layout/variables";


.button-block {
  Button[type="submit"] {
    background-color: #016CE9 !important;
    color: #fff !important;
    border: 1px solid #016CE9 !important;

    &:hover {
      background-color: #3793FF !important;
      border: 1px solid #3793FF !important;
    }

    &:disabled {
      background-color: #80B6F4 !important;
      border: 1px solid #80B6F4 !important;
    }
  }

  .cancel-btn {
    background-color: #E1E1E1 !important;
    color: #626262 !important;
    border: 1px solid #E1E1E1 !important;

    &:hover {
      background-color: #F0F0F0 !important;
      border: 1px solid #F0F0F0 !important;
    }

    &:disabled {
      background-color: #E1E1E1 !important;
      border: 1px solid #E1E1E1 !important;
    }
  }
}

.textfield-block {
  fieldset {
    top: 0px !important;
  }
}

.PrivateNotchedOutline-root-113 {
  top: 0px !important;
}

.profile-content {
  .deposit-btn:disabled {
    filter: grayscale(100%);
  }
}

.pledge-dialog {
  .MuiDialogContent-root {
    .transaction-preview .block table td:first-child {
      text-align: left;
      width: 60px;
    }

    .MuiTooltip-popper {
      .MuiTooltip-tooltip {
        text-align: left;
        margin: 0 4px;
        padding: 0;
        background: none;


        .tooltip-content {
          padding: 15px 20px;
          background-color: $white;
          box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
          color: $text-shade-3;
          border-radius: 4px;
          display: inline-block;
          width: 300px;
        }

        h5 {
          margin: 0;
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 10px;
          font-weight: 500;
          color: #111111;
        }

        ul li {
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;

          span {
            color: #016CE9;
          }
        }

        p {
          font-size: 14px;
          font-weight: 300;
          line-height: 1.4;
          margin-top: 2px;
          color: #7D7D7D;

          span {
            font-weight: 500;
            color: #111111 !important;
          }
        }

        .edao-timer {
          p {
            span {
              color: $blue  !important;
            }
          }
        }

        ul {
          color: #111;

          li {
            font-size: 14px;
            color: #7D7D7D;

            span {
              font-weight: 600;
              font-size: 14px;
              color: #016CE9;
            }
          }
        }

        .MuiTooltip-arrow {
          color: $white;
        }
      }
    }

    .container-textfield {
      margin: 26px 0;

      .MuiFormControl-root {
        width: 100%;
      }

      .MuiOutlinedInput-input {
        padding: 10px 16px 8px;
      }

      .number-label {
        display: flex;
        align-items: center;
        margin-top: 10px;

        label {
          line-height: 1.2;
        }

        .service_label {
          display: flex;
          align-items: center;
        }

        .info-btn {
          padding: 5px;
          position: relative;
          top: -1px;

          svg {
            width: 18px;
            height: 18px;
            fill: darken($white, 30%)
          }

          &.open svg {
            fill: $blue;
          }
        }

        .right {
          margin-left: auto;

          button {
            font-size: 16px;
            min-width: 0;
            color: $blue;
            line-height: 1.2;
            padding: 4px 2px;

            &:hover {
              background-color: $white;
            }
          }

          button+button {
            margin-left: 12px;
          }
        }
      }

      .textfield-block {
        position: relative;
        margin-top: 5px;

        .PrivateNotchedOutline-root-27 {
          top: 0;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        .MuiOutlinedInput-input {
          background: none;
          font-size: 26px;
          color: $black;
          font-weight: 500;
          padding-left: 20px;
        }

        .coin-logo {
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-50%);
          display: block;
          width: 30px;
          height: 30px;
        }
      }

      .coin-logo-1 {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        display: block;
        width: 22px;
      }

      .convertion {
        color: $blue;
        font-size: 14px;
        margin: 3px 0 0 10px;
      }

      .convet_in_input {
        color: #A8A8A8;
        font-size: 14px;
        position: absolute;
        top: 30%;
        left: 50%;
      }

      &.wallet-action {
        margin: 10px 0 16px;

        .MuiOutlinedInput-input {
          background-color: darken($white, 8%);
        }

        .number-label button {
          opacity: 0;
          pointer-events: none
        }
      }
    }

    .container-check {
      width: 550px;

      .MuiTypography-body1 {
        font-weight: 300;
      }

      .MuiFormControlLabel-root {
        align-items: flex-start;

        .MuiButtonBase-root {
          position: relative;
          top: -9px
        }

        .MuiCheckbox-colorPrimary.Mui-checked {
          color: $blue;
        }
      }
    }

    .container-preview {
      margin-bottom: 16px;
    }

    .container-info {
      background: lighten($blue, 51%);
      border-radius: 5px;
      padding: 10px 10px 8px;
      text-align: center;

      button,
      .text {
        font-size: 14px;
        font-weight: 300;
        display: inline-block;
      }

      button {
        color: $blue;
        position: relative;
        top: -1px;
      }

      .MuiTooltip-tooltip p span {
        color: $blue;
      }
    }
  }

  .MuiDialogActions-root {
    padding: 26px 50px 47px;

    @include custom-error;

    .button-block {
      display: flex;
      justify-content: center;

      button {
        font-size: 18px;
        font-weight: 500;
        padding: 9px;
        background-color: darken($white, 12%);
        color: lighten($black, 40%);

        &[type*="submit"] {
          background-color: $blue;
          color: $white
        }

        +button {
          margin-left: 8px;
        }
      }
    }
  }
}
.pledge-dialog-governance {
  .MuiDialogContent-root {
    .transaction-preview .block table td:first-child {
      text-align: left;
      width: 60px;
    }

    .MuiTooltip-popper {
      .MuiTooltip-tooltip {
        text-align: left;
        margin: 0 4px;
        padding: 0;
        background: none;


        .tooltip-content {
          padding: 15px 20px;
          background-color: $white;
          box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
          color: $text-shade-3;
          border-radius: 4px;
          display: inline-block;
          width: 300px;
        }

        h5 {
          margin: 0;
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 10px;
          font-weight: 500;
          color: #111111;
        }

        ul li {
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;

          span {
            color: #016CE9;
          }
        }

        p {
          font-size: 14px;
          font-weight: 300;
          line-height: 1.4;
          margin-top: 2px;
          color: #7D7D7D;

          span {
            font-weight: 500;
            color: $blue !important;
          }
        }

        .edao-timer {
          p {
            span {
              color: $blue  !important;
            }
          }
        }

        ul {
          color: #111;

          li {
            font-size: 14px;
            color: #7D7D7D;

            span {
              font-weight: 600;
              font-size: 14px;
              color: #016CE9;
            }
          }
        }

        .MuiTooltip-arrow {
          color: $white;
        }
      }
    }

    .container-textfield {
      margin: 26px 0;

      .MuiFormControl-root {
        width: 100%;
      }

      .MuiOutlinedInput-input {
        padding: 10px 16px 8px;
      }

      .number-label {
        display: flex;
        align-items: center;
        margin-top: 10px;

        label {
          line-height: 1.2;
        }

        .service_label {
          display: flex;
          align-items: center;
        }

        .info-btn {
          padding: 5px;
          position: relative;
          top: -1px;

          svg {
            width: 18px;
            height: 18px;
            fill: darken($white, 30%)
          }

          &.open svg {
            fill: $blue;
          }
        }

        .right {
          margin-left: auto;

          button {
            font-size: 16px;
            min-width: 0;
            color: $blue;
            line-height: 1.2;
            padding: 4px 2px;

            &:hover {
              background-color: $white;
            }
          }

          button+button {
            margin-left: 12px;
          }
        }
      }

      .textfield-block {
        position: relative;
        margin-top: 5px;

        .PrivateNotchedOutline-root-27 {
          top: 0;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        .MuiOutlinedInput-input {
          background: none;
          font-size: 26px;
          color: $black;
          font-weight: 500;
          padding-left: 20px;
        }

        .coin-logo {
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-50%);
          display: block;
          width: 30px;
          height: 30px;
        }
      }

      .coin-logo-1 {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        display: block;
        width: 22px;
      }

      .convertion {
        color: $blue;
        font-size: 14px;
        margin: 3px 0 0 10px;
      }

      .convet_in_input {
        color: #A8A8A8;
        font-size: 14px;
        position: absolute;
        top: 30%;
        left: 50%;
      }

      &.wallet-action {
        margin: 10px 0 16px;

        .MuiOutlinedInput-input {
          background-color: darken($white, 8%);
        }

        .number-label button {
          opacity: 0;
          pointer-events: none
        }
      }
    }

    .container-check {
      width: 550px;

      .MuiTypography-body1 {
        font-weight: 300;
      }

      .MuiFormControlLabel-root {
        align-items: flex-start;

        .MuiButtonBase-root {
          position: relative;
          top: -9px
        }

        .MuiCheckbox-colorPrimary.Mui-checked {
          color: $blue;
        }
      }
    }

    .container-preview {
      margin-bottom: 16px;
    }

    .container-info {
      background: lighten($blue, 51%);
      border-radius: 5px;
      padding: 10px 10px 8px;
      text-align: center;

      button,
      .text {
        font-size: 14px;
        font-weight: 300;
        display: inline-block;
      }

      button {
        color: $blue;
        position: relative;
        top: -1px;
      }

      .MuiTooltip-tooltip p span {
        color: $blue;
      }
    }
  }

  .MuiDialogActions-root {
    padding: 26px 50px 47px;

    @include custom-error;

    .button-block {
      display: flex;
      justify-content: center;

      button {
        font-size: 18px;
        font-weight: 500;
        padding: 9px;
        background-color: darken($white, 12%);
        color: lighten($black, 40%);

        &[type*="submit"] {
          background-color: $blue;
          color: $white
        }

        +button {
          margin-left: 8px;
        }
      }
    }
  }
}

.pledge-dialog.loading-dialog {
  .MuiDialogContent-root {
    text-align: center;
    padding: 46px 46px 54px;
  }

  .icon {
    width: 130px;
    height: 130px;
    margin-bottom: 44px;
  }

  p {
    font-size: $px-20;
    margin-bottom: 4px;
  }
}

.pledge-dialog.success-dialog,
.pledge-dialog.error-dialog {
  .MuiDialogContent-root {
    text-align: center;
    padding: 46px 46px 0;
  }

  .MuiDialogActions-root .button-block {
    margin-top: 10px;
    margin-bottom: 0;

    button {
      min-width: 247px;
    }
  }

  .icon {
    width: 130px;
    height: 130px;
    margin-bottom: 44px;
    fill: $blue;
  }

  p {
    font-size: $px-20;
    margin-bottom: 4px;
  }

  .amount-preview {
    font-weight: 500;
    color: $blue;
  }


}

.pledge-dialog.error-dialog {
  .icon {
    fill: $red;
  }
}

.container-balance {
  margin-bottom: 20px;

  label {
    font-size: 20px;
    color: lighten($black, 7%);
    display: block;
    margin-bottom: 2px;
  }

  .block {
    display: flex;
    align-items: center;

    .icon-block {
      margin-right: $px-30;

      img {
        vertical-align: middle;
        width: 50px;
        height: 50px;
      }
    }

    .amount-block {
      .amount {
        color: lighten($black, 13%);
        font-size: $px-22;
        font-weight: 700;
        line-height: 1.2;

        span {
          font-size: $px-16;
          font-weight: 400;
        }
      }

      .convertion {
        text-align: right;
        display: block;
        font-size: $px-16;
        font-weight: 400;
        line-height: 1.2;
        color: $blue;
      }
    }
  }
}

.activation-message {
  // width: 500px;
  width: 100%;
  height: 51px;
  margin: 25px 0px;
  // padding: 18px 107px 18px 22px;
  background-color: #eff6ff;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.activation-text {
  // width: 401px;
  width: 100%;
  margin-left: 15%;
  // height: 15px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #111;
}

@media only screen and (max-width: 565px) {
  .activation-message {
    white-space: normal;
  }
}

.uplg-text {
  background-color: #EFF6FF;
  width: 500px;
  height: 51px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 14px;
}

.uplg-text p span {
  color: #016ce9 !important;
  cursor: pointer !important;
}

.tlp-set .MuiTooltip-popper {
  left: 108px !important;
  top: 6px !important;
}

