.breadcrumb-area {
  &__title {
    color: var(--white-color);
    font-size: 50px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: 1.25px;
  }

  &__search-field {
    margin-left: 18vw;
  }
}

.image-holder {
  display: inline-table;
  height: 100%;
}

.mobile-image-holder {
  display: flex;
  justify-content: center;
  height: 100%;
}

.hero-img {
  height: 100%;
  width: 100%;
  position: absolute;
  object-fit: cover;
}