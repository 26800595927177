@import "../../layout/mixins";
@import "../../layout/variables";

.disclaimer-content{
  padding-top: $px-50;
  padding-bottom: $px-80;
  h1{
    color: lighten($black, 7%);
    margin: 0 0 $px-30;
    font-size: $px-34;
  }
  p{
    line-height: 1.6;
    margin: $px-30 0;
    text-align: justify;
    text-justify: inter-word;

    &:last-child{
      margin-bottom: 0;
    }
  }

  @media (max-width: 600px) {
    padding-top: $px-22;
    padding-bottom: $px-40;
    h1{
      margin: 0 0 $px-18;
      font-size: $px-22;
    }
    p{
      font-size: $px-12;
      margin: $px-16 0;
    }
  }
}