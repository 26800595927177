@import "../../layout/mixins";
@import "../../layout/variables";

.box-with-hover{
  position: relative;
  border-radius: $px-10;
  overflow: hidden;

  .link{
    width: 100%;
    display: block;
  }

  .img-container{
    img{
      vertical-align: middle;
    }
    .hover-video-container{
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;

      .hover-video-player, img{
        width: 100%;
        height: 100%;
      }

      video{
        height: 100%;
      }
    }
  }

  .details{
    position: absolute;
    bottom: -100px;
    left: 0;
    text-align: left;
    z-index: 1;
    width: 100%;
    background-color: rgba(#001f43, 0.8);
    color: $white;
    opacity: 0;
    transition: bottom .3s ease-in-out, opacity .3s ease-in-out;
    padding: $px-12 $px-14;
    display: flex;
    align-items: center;

    &.coming-soon{
      padding: $px-18 $px-14;
      text-align: center;

      h4{
        font-weight: $font-weight-m;
        width: 100%;
      }
    }

    h4{
      font-size: $px-22;
      font-weight: $font-weight-m;
      margin: 0 $px-8;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      line-height: 1;

      img{
        vertical-align: middle;
      }
    }
    p{
      font-size: $px-16;
      font-weight: $font-weight-l;
      margin: 0 $px-8;
      line-height: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.currency svg{
        width: $px-22;
      }
    }
    .MuiButton-root{
      display: block;
      padding: 0;
      min-width: auto;
      margin: 0 $px-8;
      padding: 1px;
      label{
        font-size: $px-10;
        display: block;
        margin: 0;
        color: $white;
        font-weight: $font-weight-r;
        pointer-events: none;
      }
      svg{
        pointer-events: none;
      }
      &.play-btn svg{
        width: $px-22;
        position: relative;
        top: 4px;
      }
      &.store-btn svg{
        width: $px-20;
      }
      &.star-btn {
        margin: 0 .3rem;
        svg{
          width: $px-22;
        }
      }
    }
    .left{
      display: flex;
      align-items: center;
    }
    .right{
      display: flex;
      align-items: baseline;
      margin-left: auto;
    }
  }

  &:hover{
    .details{
      bottom: 0;
      opacity: 1;
    }
  }

  @media (max-width: 959px) {
    .details{
      h4{
        font-size: $px-18;
      }
      p{
        font-size: $px-14;
      }

      &.coming-soon {
        padding: $px-20 $px-14;
        h4{
          font-size: $px-18
        }
      }
    }
  }

  @media (max-width: 600px) {
    .details{
      padding: $px-10 $px-8;
      background-color: rgba(#001f43, 0.9);
      h4{
        font-size: $px-14;
      }
      p{
        font-size: $px-10;
      }
      &.coming-soon{
        padding: $px-16 $px-8;

        h4{
          font-size: $px-14;
        }
      }
      .MuiButton-root{
        margin: 0 1vw;
        &.play-btn svg{
          width: 3.4vw;
          top: 2px;
        }
        &.store-btn svg{
          width: 3.2vw;
        }
        &.star-btn {
          margin: 0 0 0 .6vw;
          svg{
            width: 3.4vw;
          }
        }
      }
    }
  }
}