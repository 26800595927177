.search-box {

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 427px;
    height: 50px;
    background-color: var(--white-color);
    border-radius: 4px;
    margin-top: 20px;

    &__input {
        background-color: var(--white-color);
        width: 88%;
        border-radius: 4px;
        border: unset;
    }

    &__btn {
        display: flex;
        justify-content: center;
        background-color: var(--primary-load-more-border);
        color: var(--white-color);
        font-size: 22px;
        height: 40px;
        width: 46px;
        border-radius: 4px;
        border: unset;
        cursor: pointer;
        
        .icon-magnifier {
            padding: 8px;
        }

        :hover {
            opacity: 0.3;
        }
    }
}