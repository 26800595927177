@import "../../layout/mixins";
@import "../../layout/variables";

.transaction-preview{
  .block{
    border-radius: 10px;
    background-color: darken($white, 5%);
    overflow: hidden;

    .align-left {
      text-align: left;
      width: 100px;
    }

    table{
      border-collapse: collapse;

      thead{
        border-bottom: 1px solid darken($white, 16%);
      }

      th, td{
        padding: 10px 20px;
        font-weight: 600;
        text-align: right;
      }

      th{
        vertical-align: middle;
        line-height: 1;
        font-size: 20px;
        padding-top: 12px;

      }

      td{
        white-space: nowrap;
        &:first-child{
          font-size: $px-20;
          width: 108px;
        }
        &:nth-child(3){
          width: 181px;
        }
        .amount {
          color: lighten($black, 13%);
          font-size: $px-22;
          font-weight: 700;
          line-height: 1.2;
          span{
            font-size: $px-16;
            font-weight: 400;
          }
        }
        .currency{
          color: $blue;
          font-weight: 400;
        }
      }
    }
  }

  .arrow-block{
    text-align: center;
    margin: 8px 0;
    svg{
      width: 13px;
      height: auto;
      fill: $blue;
    }
  }

  @media (max-width: 670px) {
    .block{
      border-radius: 5px;

      table{
        th{
          font-size: 12px;
          padding-top: 12px;
        }

        td{
          &:first-child{
            font-size: 12px;
          }
          .amount {
            font-size: 14px;
            span{
              font-size: 12px;
            }
          }
          .currency{
            font-size: 12px;
          }
        }
      }
    }
  }
}
