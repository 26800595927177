@import "../../layout/mixins";
@import "../../layout/variables";

.imx-modal {
  .MuiPaper-root {

    .MuiDialogActions-root {
      @include custom-error;

      .button-block {
        display: flex;
        justify-content: center;
        margin-top: 8px;

        button {
          font-size: 18px;
          font-weight: 500;
          padding: 9px;
          background-color: darken($white, 12%);
          color: lighten($black, 40%);

          &[type*="submit"],
          &.MuiButton-containedPrimary {
            background-color: $blue;
            color: $white;
            position: relative;

            .text {
              font-weight: 500;
              position: relative;
              font-size: 18px;
              right: 0;
              transition: right 0.4s ease-in-out;
            }
          }

          &.Mui-disabled {
            color: $white;
            background: lighten($black, 70%);

            .text {
              right: 10px;
              opacity: 0.8;
            }
          }

          +button {
            margin-left: 8px;
          }
        }

        .imx-button-container {
          &.hide {
            display: none;
          }
        }
      }

      @media (max-width: 670px) {
        .custom-error {
          .MuiAlert-message {
            font-size: 13px;
          }

          .icon {
            width: 20px;
            height: 18px;
            margin-right: $px-6;
            min-width: 20px;
          }
        }

        .button-block {
          margin-top: 8px;

          button {
            font-size: 14px;
            padding: 8px;

            &[type*="submit"] {
              .text {
                font-size: 14px;
              }
            }
          }
        }
      }
    }

    .container-textfield {
      margin: 26px 0 0;

      .MuiFormControl-root {
        width: 100%;
      }

      .MuiOutlinedInput-input {
        padding: 10px 16px 8px;
      }

      .textfield-block {
        position: relative;
        margin-top: 5px;

        .PrivateNotchedOutline-root-27 {
          top: 0;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        .MuiOutlinedInput-input {
          background: none;
          font-size: 26px;
          color: $black;
          font-weight: 500;
          padding-left: 20px;
        }

        .coin-logo {
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-50%);
          display: block;
          width: 30px;
          height: 30px;
        }
      }
    }
  }

  &.imx-deposit-dialog {
    .MuiPaper-root {
      .container-balance {
        label {
          margin-bottom: 17px;
        }

        .block {
          .icon-block {
            padding: 10px 15px;
            background-color: #DDDDDD;
            border-radius: 99px;
            line-height: 1;
            margin-right: 20px;
          }

          .amount-block {
            .amount {
              span {
                font-weight: 700;
              }
            }
          }
        }
      }

      .container-textfield {
        .number-label {
          label {
            font-weight: 500;
            font-size: 16px;
          }
        }

        .convertion {
          color: #A8A8A8;
          font-size: 14px;
          position: absolute;
          top: 30%;
          left: 55%;

          @media (max-width: 520px) {
            top: 105%;
            left: 0;
          }
        }
      }
    }
  }
}

.imx-button-container {
  position: absolute;
  top: -80px;
  right: 0;

  .MuiButtonBase-root {
    padding: 14px 20px;
    background-color: #016CE9;

    &:hover {
      background-color: #016CE9;
    }

    span {
      font-weight: 500;
      font-size: 18px;
      line-height: 1.2;
    }

    &.balance-button {
      padding: 8px;
      white-space: nowrap;

      span {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;

        svg {
          margin-left: 8px;
        }

        span {
          display: flex;
          align-items: center;

          &:first-child {
            margin-right: 15px;
          }
        }
      }
    }
  }
}

.imx-balance-dialog {
  width: 218px;
  background: #FAFAFA;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  padding: 0 20px;
  text-align: center;

  .header {
    font-weight: 500;
    font-size: 14px;
    padding: 16px 0;
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #111111;
    padding: 23px 0;

    .amount-block {
      .amount {
        font-weight: 700;
        font-size: 24px;

        span {
          font-weight: 400;
          font-size: 16px;
        }
      }
    }

    .icon-block {
      margin-left: 8px;

      svg {
        height: 20px;
      }
    }
  }

  .button-block {
    flex-direction: column;

    button {
      background: #016CE9;
      border-radius: 5px;
      padding: 14px 0;
      font-weight: 500;
      font-size: 18px;
      line-height: 1.2;

      &.MuiButton-text {
        font-weight: 400;
        font-size: 16px;
        background-color: transparent;
        color: $blue;
        padding: 18px 0;

        &.Mui-disabled {
          color: lighten($black, 70%);
        }
      }

      +button {
        margin-left: 0;
      }
    }
  }
}