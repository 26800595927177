// @import "../../../layout/mixins";
// @import "../../../layout/variables";

.buttons-block {
  button{
    color: #016CE9 !important;
    border: 1px solid #016CE9 !important;

    &:hover {
      background-color: #016CE9 !important;
      color: #fff !important;
      border: 1px solid #016CE9 !important;
    }

    &:disabled {
      background-color: #fff !important;
      color: #ACACAC !important;
      border: 1px solid #ACACAC !important;
    }
  }
}
// // .zera-wrapper{
// //   color: #111111;
// //   padding: 2rem;

// //   // [theme.breakpoints.down('xs')]: {
// //   //   padding: '2rem .5rem',
// //   // },

// //   // [theme.breakpoints.down('sm')]: {
// //   //   paddingTop: '5rem',
// //   // },
// // }
// .whole-num-large {
//   font-size: 50px;
//   top: 0.25rem;
//   line-height: 1;
//   font-weight: bold;

//   // @media (max-width: 600px) {
//   //   font-size: 28px;
//   // }

//   @media (max-width: 600px) {
//     font-size: 28px;
//   }
// }

// .decimal-num-large {
//   font-size: 30px;
//   line-height: 0.8;

//   // @media (max-width: 600px) {
//   //   font-size: 22px;
//   // }

//   @media (max-width: 600px) {
//     font-size: 16px;
//     line-height: 1;
//     top: 2px;
//   }
// }

// .whole-num {
//   font-size: 30px;
//   line-height: 1;
//   font-weight: bold;

//   // @media (max-width: 600px) {
//   //   font-size: 22px;
//   // }

//   @media (max-width: 600px) {
//     // font-size: 4vw;
//     font-size: 16px;
//     line-height: 1;
//   }
// }

// .decimal-num {
//   font-size: 18px;
//   line-height: 1;

//   // @media (max-width: 600px) {
//   //   font-size: 16px;
//   // }

//   @media (max-width: 600px) {
//     font-size: 10px;
//   }
// }

// .details-icon {
//   height: 51px;
//   width: auto;
//   filter: invert(73%) sepia(25%) saturate(534%) hue-rotate(180deg) brightness(103%) contrast(102%);
//   z-index: 1;
//   position: relative;
//   left: calc(100% - 3rem);

//   @media (max-width: 960px) {
//     height: 35px;
//     position: relative;
//     left: calc(100% - 2.5rem);
//   }

//   @media (max-width: 600px) {
//     height: 35px;
//     position: relative;
//     left: calc(100% - 2rem);
//   }
// }

// .token-details {
//   padding: 1rem;
//   background-color: #F7F7F7;
//   border-radius: .5rem;
//   display: flex;
//   justify-content: space-between;
//   align-items: flex-end;
//   position: relative;

//   @media (max-width: 960px) {
//     padding: 1.5rem .75rem;
//   }

//   @media (max-width: 600px) {
//     padding: .5rem;
//   }
// }

// .details-text-container {
//   z-index: 2;
//   position: absolute;
//   top: .75rem;

//   // @media (max-width: 960px) {
//   //   top: .75rem;
//   // }

//   @media (max-width: 600px) {
//     top: .25rem;
//   }
// }

// .amount {
//   & > * {
//     display: inline-block;
//   }

//   button {
//     text-transform: none;
//     font-size: 16px;

//     @media (max-width: 600px) {
//       font-size: 14px;
//     }
//   }
// }
