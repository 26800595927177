@import "../../layout/mixins";
@import "../../layout/variables";

.ace-dialog{
  .paper-root{
    box-shadow: 3.5px 3.5px 10px 0 rgba(7, 6, 6, 0.3);
    border-radius: 10px;
    width: 100%;
  }

  .MuiDialogTitle-root{
    text-align: center;
    border-bottom: 1px solid darken($white, 20%);
    color: $text-shade-2;
    h2{
      font-size: 24px;
    }
  }

  .MuiDialogContent-root{
    padding: 30px 50px 2px;
  }
    .MuiDialogActions-root {
      padding: 0px !important;

      > :not(:first-child) {
        margin: 0;
      }
    }

  @media (max-width: 670px){
    .MuiDialog-paperWidthSm.MuiDialog-paperScrollBody {
      max-width: 100%;
    }
    .MuiDialog-paper {
      margin: 0;
    }
    .MuiDialogTitle-root{
      padding: 16px 16px 12px;
      h2{
        font-size: 18px;
      }
    }
    .MuiDialogContent-root{
      padding: 22px 30px 2px;
    }
    .MuiDialogActions-root{
      padding: 22px 30px 30px;
    }
  }
}
