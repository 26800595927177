@import "../../layout/mixins";
@import "../../layout/variables";

.home-content {
  .section.section-slider {
    text-align: center;
    margin: 0 auto;
    width: 100%;
    padding-top: $px-40;

    @media (max-width: 959px) {
      padding-top: 0;
    }

    .swiper-area {
      width: 100%;
      padding: 0 0 30px;

      // slider NEXT / PREV
      .swiper-button-prev {
        left: 24px;
      }
      .swiper-button-next {
        right: 24px;
      }

      .swiper-button-prev,
      .swiper-button-next {
        color: rgba(255, 255, 255, 0.5);
      }
      // end

      .slide-content-block {
        position: relative;

        .bg-image {
          width: 100%;
          vertical-align: middle;
        }

        .hover-video-container {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
          height: 100%;
          width: 100%;
          vertical-align: middle;

          .hover-video-player,
          img,
          video {
            width: 100%;
            height: 100%;
            vertical-align: middle;
          }
        }

        .slide-content-box {
          position: absolute;
          top: 48%;
          transform: translateY(-50%);
          left: 10%;
          color: #fff;
          text-align: left;
          opacity: 0;
          pointer-events: none;
          max-width: 450px;

          .title,
          .excerpt {
            pointer-events: none;
            opacity: 1;
            transition: opacity 0.3s ease-in-out;

            &.show {
              opacity: 1;
            }
          }

          .title {
            margin: 0 0 $px-10 0;
            font-size: 60px;
            line-height: 1.1;
          }

          .excerpt {
            margin-top: 0;
            font-size: $px-30;
            font-weight: $font-weight-l;
          }
        }

        .slide-content-buttons {
          position: absolute;
          left: 10%;
          bottom: 15%;
          opacity: 0;

          .btn {
            border: 1px solid $white;
            background: $black;
            color: $white;
            font-size: $px-14;
            border-radius: 999px;
            line-height: 1;
            min-width: 180px;
            padding: 12px;
            margin-right: 0.7rem;

            &:hover {
              color: #f8941c;
              border-color: #f8941c;
            }

            &.d-none {
              display: none !important;
            }
          }
        }
      }

      .swiper-slide-visible.swiper-slide-active.pc-view.has-video-hover {
        &:hover {
          .slide-content-block .slide-content-box {
            .title,
            .excerpt {
              opacity: 0;
            }

            &.show {
              .title,
              .excerpt {
                opacity: 1;
              }
            }
          }
        }
      }

      .swiper-slide-active {
        .slide-content-block .slide-content-box,
        .slide-content-block .slide-content-buttons {
          opacity: 1;

          &.has-video-hover {
            opacity: 1;

            &.hidden {
              opacity: 0;
            }
          }
        }
      }

      @media (min-width: 768px) and (max-width: 1460px) {
        .slide-content-block {
          .slide-content-box {
            max-width: 27vw;
            .title {
              font-size: 4vw;
            }
            .excerpt {
              font-size: 2vw;
            }
          }

          .slide-content-buttons {
            .btn {
              font-size: $px-12;
            }
          }
        }
      }

      @media (max-width: 959px) {
        .slide-content-block {
          .slide-content-box {
            max-width: 60%;
            .title {
              font-size: 6vw;
              margin-bottom: 1vw;
            }
            .excerpt {
              font-size: 4vw;
            }
          }

          .slide-content-buttons {
            bottom: 4vw;
            .btn {
              font-size: 2vw;
              min-width: 18vw;
              padding: 1.5vw;
              margin-right: 0;
              margin-bottom: 2vw;
              display: block;
            }
          }
        }
      }

      @media (max-width: 767px) {
        .swiper-button-prev {
          left: 0;
          display: none;
        }
        .swiper-button-next {
          right: 0;
          display: none;
        }
      }

      // custom that cannot be achieve in dynamic ==============
      .slide-content-block.crypto-kitties {
        .slide-content-box {
          max-width: 500px;
        }

        @media (min-width: 768px) and (max-width: 1460px) {
          .slide-content-box {
            max-width: 36vw;
          }
        }
        @media (max-width: 959px) {
          .slide-content-box {
            max-width: 50%;
            top: 38%;
            .excerpt {
              white-space: nowrap;
            }
          }
        }
      }
      .slide-content-block.decentraland {
        .slide-content-box {
          max-width: 600px;
        }
        @media (min-width: 768px) and (max-width: 1460px) {
          .slide-content-box {
            max-width: 40vw;
          }
        }
        @media (max-width: 959px) {
          .slide-content-box {
            max-width: 50%;
            .excerpt {
              white-space: nowrap;
            }
          }
        }
      }
      .slide-content-block.xangle {
        .slide-content-box {
          max-width: 350px;
        }
        @media (min-width: 768px) and (max-width: 1460px) {
          .slide-content-box {
            max-width: 24vw;
          }
        }
        @media (max-width: 959px) {
          .slide-content-box {
            max-width: 48%;
          }
        }
      }
      .slide-content-block.opensea {
        .slide-content-box {
          max-width: 500px;
        }
        @media (min-width: 768px) and (max-width: 1460px) {
          .slide-content-box {
            max-width: 36vw;
          }
        }
        @media (max-width: 959px) {
          .slide-content-box {
            max-width: 60%;
          }
        }
      }
      .slide-content-block.enftee {
        @media (max-width: 959px) {
          .slide-content-box {
            max-width: 34%;
            top: 55%;
          }
        }
      }
      .slide-content-block.maker-dao {
        @media (min-width: 768px) and (max-width: 1460px) {
          .slide-content-box {
            max-width: 30vw;
          }
        }
        @media (max-width: 959px) {
          .slide-content-box {
            max-width: 48%;
          }
        }
      }
      .slide-content-block.uniswap {
        @media (min-width: 768px) and (max-width: 1460px) {
          .slide-content-box {
            max-width: 28vw;
          }
        }
        @media (max-width: 959px) {
          .slide-content-box {
            max-width: 46%;
          }
        }
      }
      // custom end ==============
    }

    // slider viewports
    @media (min-width: 960px) {
      .swiper-area {
        .slide-content-block {
          border-radius: $px-10;
          overflow: hidden;
        }

        // overwrite tilt next and prev slides
        .swiper-wrapper {
          .swiper-slide {
            transform: translate3d(840px, 0px, -3500px) rotateX(0deg)
              rotateY(70deg) scale(2.3) !important;
            opacity: 0;
            &[style*="z-index: -2"] {
              transform: translate3d(840px, 0px, -5000px) rotateX(0deg)
                rotateY(70deg) scale(2.3) !important;
              opacity: 1;
            }
            &[style*="z-index: -1"] {
              opacity: 1;
            }
            &.swiper-slide-prev {
              transform: translate3d(700px, 0px, -700px) rotateX(0deg)
                rotateY(70deg) scale(1) !important;
              opacity: 1;
            }
            &.swiper-slide-active.swiper-slide-visible {
              transform: translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg)
                scale(1) !important;
              opacity: 1;
            }
            &.swiper-slide-next {
              transform: translate3d(-700px, 0px, -700px) rotateX(0deg)
                rotateY(-70deg) scale(1) !important;
              opacity: 1;
            }
            &.swiper-slide-next + .swiper-slide {
              transform: translate3d(-840px, 0px, -3500px) rotateX(0deg)
                rotateY(-70deg) scale(2.3) !important;
              opacity: 1;
            }
            &.swiper-slide-next ~ [style*="z-index: -2"] {
              transform: translate3d(840px, 0px, -8000px) rotateX(0deg)
                rotateY(100deg) scale(3.4) !important;
              .swiper-slide-shadow-right,
              .swiper-slide-shadow-left {
                background-image: none;
              }
            }
          }
        }
        // end
      }
    }
    @media (min-width: 1920px) {
      max-width: 1920px;
      .swiper-area {
        padding: 10px 17.62% 30px;

        .swiper-button-prev {
          left: 20%;
        }
        .swiper-button-next {
          right: 20%;
        }
      }
    }
    @media (min-width: 1090px) and (max-width: 1952px) {
      max-width: 1740px;
      .swiper-area {
        padding: 10px 14% 30px;

        .swiper-button-prev {
          left: 17%;
        }
        .swiper-button-next {
          right: 17%;
        }
      }
    }
    @media (min-width: 1090px) and (max-width: 1772px) {
      max-width: 1478px;
      .swiper-area {
        padding: 10px 11.68% 30px;

        .swiper-button-prev {
          left: 14%;
        }
        .swiper-button-next {
          right: 14%;
        }

        .swiper-wrapper {
          .swiper-slide {
            transform: translate3d(840px, 0px, -3200px) rotateX(0deg)
              rotateY(70deg) scale(2.1) !important;
            &.swiper-slide-prev {
              transform: translate3d(700px, 0px, -450px) rotateX(0deg)
                rotateY(70deg) scale(0.9) !important;
            }
            &.swiper-slide-next {
              transform: translate3d(-700px, 0px, -450px) rotateX(0deg)
                rotateY(-70deg) scale(0.9) !important;
            }
            &.swiper-slide-next + .swiper-slide {
              transform: translate3d(-840px, 0px, -3200px) rotateX(0deg)
                rotateY(-70deg) scale(2.1) !important;
            }
            &.swiper-slide-next ~ [style*="z-index: -2"] {
              transform: translate3d(840px, 0px, -8300px) rotateX(0deg)
                rotateY(100deg) scale(3.4) !important;
            }
          }
        }
      }
    }
    @media (min-width: 960px) and (max-width: 1510px) {
      max-width: 1248px;
      .swiper-area {
        padding: 10px 9% 30px;

        .swiper-button-prev {
          left: 12%;
        }
        .swiper-button-next {
          right: 12%;
        }

        .swiper-wrapper {
          .swiper-slide {
            &.swiper-slide-prev {
              transform: translate3d(630px, 0px, -450px) rotateX(0deg)
                rotateY(70deg) scale(0.9) !important;
            }
            &.swiper-slide-next {
              transform: translate3d(-630px, 0px, -450px) rotateX(0deg)
                rotateY(-70deg) scale(0.9) !important;
            }
          }
        }
      }

      .swiper-container-3d {
        perspective: 1300px;
      }
    }
    @media (min-width: 960px) and (max-width: 1280px) {
      overflow: hidden;
      position: relative;
      height: 590px;
      .swiper-area {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 1640px;
        padding: 10px 260px 30px;

        .swiper-button-prev {
          left: 290px;
        }
        .swiper-button-next {
          right: 290px;
        }
      }
      .swiper-container-3d {
        perspective: 1630px;
      }
    }
    @media (min-width: 960px) and (max-width: 1180px) {
      height: 545px;
      .swiper-area {
        width: 1540px;

        .swiper-wrapper {
          .swiper-slide {
            &.swiper-slide-prev {
              transform: translate3d(600px, 0px, -350px) rotateX(0deg)
                rotateY(70deg) scale(0.9) !important;
            }
            &.swiper-slide-next {
              transform: translate3d(-600px, 0px, -350px) rotateX(0deg)
                rotateY(-70deg) scale(0.9) !important;
            }
          }
        }
      }
    }
    @media (min-width: 960px) and (max-width: 1080px) {
      height: 500px;
      .swiper-area {
        width: 1440px;

        .swiper-wrapper {
          .swiper-slide {
            &.swiper-slide-prev {
              transform: translate3d(530px, 0px, -350px) rotateX(0deg)
                rotateY(70deg) scale(0.9) !important;
            }
            &.swiper-slide-next {
              transform: translate3d(-530px, 0px, -350px) rotateX(0deg)
                rotateY(-70deg) scale(0.9) !important;
            }
          }
        }
      }
    }

    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-container-horizontal > .swiper-pagination-bullets {
      bottom: 0;
    }

    .modal-player-block {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .MuiButtonBase-root {
        .MuiSvgIcon-root {
          opacity: 0.5;
          color: $white;
          width: 3em;
          height: 3em;
        }

        &:hover .MuiSvgIcon-root {
          opacity: 0.9;
        }
      }
    }

    .swiper-wrapper {
      .swiper-slide {
        .details {
          display: none;
        }
      }
      .swiper-slide.swiper-slide-visible.swiper-slide-active {
        .details {
          position: absolute;
          bottom: 0;
          left: 0;
          text-align: left;
          z-index: 999;
          width: 100%;
          background-color: rgba(#111, 0.8);
          color: $white;
          opacity: 0;
          display: flex;
          transition: bottom 0.3s ease-in-out, opacity 0.3s ease-in-out;
          padding: $px-12 $px-14;
          align-items: center;

          &.coming-soon {
            padding: $px-18 $px-14;
            text-align: center;

            h4 {
              font-weight: $font-weight-m;
              width: 100%;
            }
          }

          h4 {
            font-size: $px-22;
            font-weight: $font-weight-m;
            margin: 0 $px-8;
            margin-left: $px-26;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            line-height: 1;

            img {
              vertical-align: middle;
              max-height: 45px;
            }
          }
          p {
            font-size: $px-16;
            font-weight: $font-weight-l;
            margin: 0 $px-8;
            line-height: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &.currency svg {
              width: $px-16;
            }
          }
          .MuiButton-root {
            display: block;
            padding: 0;
            min-width: auto;
            margin: 0 15px;
            padding: 1px;
            text-transform: none;
            text-align: center;

            img {
              margin: 0 auto;
            }
            label {
              font-size: $px-12;
              display: block;
              margin: 0;
              color: $white;
              font-weight: $font-weight-r;
              pointer-events: none;
              line-height: 1;
              margin-top: 5px;
            }
            svg {
              pointer-events: none;
            }
            &.play-btn svg {
              width: $px-22;
              position: relative;
              top: 1px;
            }
            &.store-btn svg {
              width: $px-20;
            }
            &.star-btn {
              margin: 0 0.3rem;
              svg {
                width: $px-22;
              }
            }
            &.white-paper-btn {
              img {
                position: relative;
                top: 4px;
              }
            }
          }
          .left {
            display: flex;
            align-items: center;
          }
          .right {
            display: flex;
            align-items: center;
            margin-left: auto;
          }
        }
      }

      &:hover {
        .swiper-slide.swiper-slide-visible.swiper-slide-active .details {
          bottom: 0;
          opacity: 1;
        }
      }
    }
  }
}

.modal-player {
  display: flex;
  align-items: center;
  justify-content: center;

  &[aria-hidden*="true"] {
    display: none;
  }

  .player-wrapper {
    position: relative;
    width: 80%;
    height: 90%;
    outline: none;
    @media (max-width: 959px) {
      width: calc(100% - 48px);
      height: 80%;
    }
    @media (max-width: 600px) {
      width: calc(100% - 32px);
      height: 300px;
    }
  }
  .react-player {
    outline: none;

    video {
      &:focus {
        outline: none;
      }
    }
  }
}
p.policy-modal-body.osiris-browser-modal {
  display: inline-block;
  font-size: 17px;
  width: 100%;
  height: auto;
  p {
   
  }
}
.MuiDialog-root.custom-dialog.osiris-browser-modal {
  .MuiDialogContent-root {
    padding: 30px 30px 2px;
  }
}
.home-content .section.section-slider .swiper-wrapper .swiper-slide.swiper-slide-visible.swiper-slide-active .details .play-btn.signUp-btn label {
  // font-size: 18px;
  text-transform: uppercase;
}

.page-spinner {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #00000078;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}