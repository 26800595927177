@import "../../layout/mixins";
@import "../../layout/variables";

.get-it-btn.MuiButtonBase-root {
  margin: 6px;

  .content-wrapper {
    display: inline-flex;
    border-radius: $px-10;
    background: $blue;
    padding: $px-14 $px-18;
    min-width: 240px;
  }

  .icon {
    width: $px-40;
    height: $px-42;
    fill: $white;
    margin-right: $px-18;
  }

  .text {
    color: $white;
    text-align: left;
    div {
      &.text-small {
        display: block;
        line-height: 1;
        font-size: $px-14;
        font-weight: $font-weight-l;
        margin-bottom: 0.2rem;
      }
      &.text-big {
        display: block;
        line-height: 1;
        font-size: $px-24;
      }
    }
  }

  &:disabled {
    .content-wrapper {
      background: lighten($black, 39%);
    }

    .icon,
    .text {
      opacity: 0.3;
    }
  }

  &.apple-btn {
    .icon {
      position: relative;
      top: -4px;
    }
    .text {
      margin-right: 20px;
    }
  }

  @media (max-width: 1111px) and (min-width: 960px) {
    .content-wrapper {
      border-radius: 0.9vw;
      padding: 1.2vw 1.6vw;
      min-width: 22vw;
      height: 6vw;
    }

    .icon {
      width: 3.6vw;
      height: 3.6vw;
      margin-right: 1.6vw;
    }

    .text {
      div {
        &.text-small {
          font-size: 1.2vw;
          margin-bottom: 0.2rem;
        }
        &.text-big {
          font-size: 2.3vw;
        }
      }
    }

    &.apple-btn {
      .icon {
        top: -0.2vw;
      }
      .text {
        margin-right: 1.6vw;
      }
    }
  }

  @media (max-width: 959px) {
    border-radius: 999px;

    .bg-icon {
      width: 76px;
      position: absolute;
      z-index: 1;
      fill: $blue;
    }

    .content-wrapper {
      min-width: auto;
      background: transparent;
      position: relative;
      z-index: 2;
      padding: $px-18;
    }

    .icon {
      margin-right: 0;
    }

    .text {
      display: none;
    }

    &:disabled {
      .bg-icon {
        fill: lighten($black, 39%);
      }

      .content-wrapper {
        background: none;
      }

      .icon,
      .text {
        opacity: 0.3;
      }
    }

    &.windows-btn {
      display: none;
    }

    &.apple-btn {
      .icon {
        top: -2px;
      }
      .text {
        margin-right: 0;
      }
    }

    &.google-btn {
      .icon {
        position: relative;
        left: 4px;
      }
    }
  }

  @media (max-width: 599px) {
    margin: 1vw;

    .bg-icon {
      width: 12vw;
    }

    .content-wrapper {
      padding: 2.8vw;
    }

    .icon {
      width: 6.6vw;
    }

    &.apple-btn {
      .icon {
        top: -0.4vw;
      }
    }

    &.google-btn {
      .icon {
        left: 0.6vw;
      }
    }
  }
}
