@import "../../layout/mixins";
@import "../../layout/variables";

.download-content{
  .section{
    padding: $px-20 0;
  }

  .section-mv{
    padding: $px-80 0;
    text-align: center;
    background: url('../../assets/images/page-download/mv-download.jpg') no-repeat center;
    background-size: cover;

    .heading-block{
      max-width: 980px;
      margin: 0 auto;
      color: $white;
      h1{
        font-size: $px-50;
        line-height: 1.2;
        margin: 0;
        margin-bottom: $px-16;
        color: $white;
      }
      p{
        font-size: $px-26;
        font-weight: $font-weight-l;
        opacity: 0.8;
        margin: 0;
        margin-bottom: $px-22;
      }
    }

    .video-block{
      max-width: 900px;
      margin: 0 auto;
      width: auto;
      margin-top: $px-36;

      .react-player {
        padding-top: 56.25%;
        position: relative;
      }
      .react-player > div {
        position: absolute; 
        top: 0;
      }
    }

    .btn-block{
      display: flex;
      align-items: center;
      max-width: 1046px;
      margin: 0 auto;
      overflow: hidden;

      .image-label{
        min-width: 282px;
        margin-right: 14px;
      }

      @media (max-width: 1111px) {
        max-width: none;
        .image-label{
          min-width: auto;
          width: 22vw;
        }
      }

      @media (max-width: 959px){
        height: 88px;
        display: inline-flex;
        justify-content: center;
        .image-label{
          min-width: 282px;
          width: auto;
        }
      }

      @media (max-width: 599px){
        height: 14.2vw;
        .image-label{
          min-width: auto;
          width: 50vw;
          margin-right: 2.4vw;
        }
      }
    }

    @media (max-width: 1280px) {
      .heading-block{
        h1{
          font-size: $px-44;
        }
        p{
          font-size: $px-24;
        }
      }
    }

    @media (max-width: 600px) {
      padding: $px-50 0;
      .heading-block{
        h1{
          font-size: $px-30;
          margin-bottom: $px-18;
        }
        p{
          font-size: $px-20;
          margin-bottom: $px-8;
        }
      }
    }
  }
  
  .section-how-to{
    padding: $px-70 0;
    
    > .section-container{
      max-width: 1111px;
      margin: 0 auto;
    }

    .heading-block{
      text-align: center;
      margin-bottom: $px-80;
      h2{
        font-size: $px-46;
        margin-top: $px-20;
        margin-bottom: $px-26;
        line-height: 1.2;
      }
      p{
        font-size: $px-26;
        margin: 0;
        line-height: 1.2;
        color: $text-shade-2;
      }
    }
    .block{
      &.first-block{
        margin-bottom: $px-20;
      }

      .img-fluid{
        max-width: 500px;
      }

      .details-contanier{
        max-width: 350px;
        margin: 0 auto;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }

      h3{
        font-size: $px-40;
        margin: 0 0 $px-24;
        line-height: 1.2;
        font-weight: $font-weight-m;
      }

      p{
        font-size: $px-22;
        margin: 0 0 $px-30;
        color: $text-shade-3;
      }

      .link{
        color: $blue;
        font-size: $px-20;
        font-weight: $font-weight-m;
        display: inline-flex;
        align-items: center;
        text-decoration: none;

        .icon{
          width: $px-16;
          margin-left: .5rem;
          transition: margin-left .2s ease-in-out;
        }

        &:hover{
          .icon{
            margin-left: 1rem;
          }
        }
      }
    }

    @media (max-width: 1280px) {
      .heading-block{
        h2{
          font-size: $px-44;
        }
      }
    }

    @media (max-width: 959px) {
      .block{
        text-align: center;

        >.MuiGrid-container{
          justify-content: center;
        }

        .MuiGrid-item{
          width: 100%;
        }

        .link{
          justify-content: center;
        }

        .img-fluid{
          max-width: 600px;
          width: 100%;
        }

        h3 .img-fluid{
          width: auto;
        }

        &.first-block{
          margin-bottom: $px-40;
        }

        &.second-block{
          .MuiGrid-container{
            flex-direction: column-reverse;
          }
        }
      }
    }

    @media (max-width: 600px) {
      padding: $px-60 0;
      .heading-block{
        margin-bottom: $px-30;
        h2{
          font-size: $px-28;
          margin-top: 0;
        }
        p{
          font-size: $px-20;
        }
      }

      .block{
        h3{
          font-size: $px-24;
          margin-bottom: $px-20;
        }
        p{
          font-size: $px-20;
          margin-bottom: $px-20;
        }
        .link{
          font-size: $px-20;
        }
        &.first-block{
          margin-bottom: $px-30;
        }
      }
    }
  }

  .section-big-btn{
    text-align: center;
    background: #f6f8fc;
    padding: $px-50 0;

    h2{
      font-size: $px-36;
      font-weight: $font-weight-m;
      line-height: 1;
      margin: 0;
      margin-bottom: $px-16;
    }
    @media (max-width: 600px) {
      h2{
        font-size: $px-26;
        line-height: 1.2;
        margin-bottom: $px-8;
      }
    }
  }
}