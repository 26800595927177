@import "../../../../layout/mixins";
@import "../../../../layout/variables";

// .zera-wrapper{
//   color: #111111;
//   padding: 2rem;

//   // [theme.breakpoints.down('xs')]: {
//   //   padding: '2rem .5rem',
//   // },

//   // [theme.breakpoints.down('sm')]: {
//   //   paddingTop: '5rem',
//   // },
// }
//.pagination{
//  margin: 32px 0px 18px 0px;
//  ul {
//    margin: 0 auto;
//    li button{
//      border-radius:8px;
//      font-size: 12px !important;
//      border-color: #A6A6A6;
//      color: #A6A6A6;
//
//    }
//    .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
//      background-color: #016CE9 !important;
//      border-color: #016CE9;
//      color: #fff;
//      &:hover{
//        color: #fff !important;
//      }
//    }
//    .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root:hover {
//      color: #016CE9 !important;
//      background-color:#fff;
//      border-color:#016CE9 !important;
//    }
//  }
//}


.amount {
  &>* {
    display: inline-block;
  }

  button {
    text-transform: none;
    font-size: 16px;

    @media (max-width: 600px) {
      font-size: 14px;
    }
  }

}
.ace-convert-fiat {
  margin-top: -25px;
}

.ace-dialog {
  p {
    font-size: 16px !important;
  }

  .contt-mid {
    margin: 30px 0px !important;
  }

  .contt-mid p {
    color: #000000 !important;
  }

  .blkbtn {
    display: flex;
    justify-content: space-between;
    margin: 47px 0px 42px 0px;

    Button {
      width: 49%;
      height: 50px;
      background-color: #ffffff;
      color: #016ce9;
      border: 2px solid #016ce9;
      font-size: 18px;
      font-weight: 500;

      &:hover {
        background-color: #016ce9;
        color: #ffffff;
      }
    }
  }

  .footer {
    background-color: #000000 !important;
  }

  .foot-main {
    display: flex;
    justify-content: space-between;
    background-color: #000000 !important;
    padding: 14px 50px;
  }
}

.participation-table {
  border-collapse: collapse;

  th {
    font-size: 20px;
    width: 14%;
    text-align: center;
    padding: 11px 0px;

    &:nth-child(3) {
      width: 20%;
    }

    &:nth-child(4) {
      width: 15%;
    }
  }

  td {
    font-size: 18px;
    color: #626262;
    width: 14%;
    text-align: center;
    padding: 11px 0px;

    img {
      margin-left: 9px;
    }

    Button {
      color: #016ce9;
      border: 1px solid #016ce9;
    }

    &:nth-child(3) {
      width: 20%;
    }

    &:nth-child(2) {
      text-align: right;
    }

    &:nth-child(4) {
      width: 15%;
    }
  }

  tr {
    border-bottom: 1px solid #dddddd;

  }
}

.history-table {
  border-collapse: collapse;

  th {
    font-size: 20px;
    width: 33.3%;
    text-align: center;
    padding: 11px 0px;
    padding-left: 25px;

    &:nth-child(1) {
      text-align: start;
    }

    &:nth-child(2) {
      text-align: start;
    }
  }

  td {
    font-size: 18px;
    color: #626262;
    width: 33.3%;
    text-align: center;
    padding: 11px 0px;
    padding-left: 25px;

    img {
      margin-left: 9px;
    }

    Button {
      color: #016ce9;
      border: 1px solid #016ce9;
    }

    &:nth-child(3) {
      width: 20%;
    }

    &:nth-child(1) {
      text-align: start;
    }

    &:nth-child(2) {
      text-align: start;
    }

    &:nth-child(3) {
      text-align: right;
    }
  }

  tr {
    border-bottom: 1px solid #dddddd;

  }
}

.whole-num-large {
  font-size: 50px;
  top: 0.25rem;
  line-height: 1;
  font-weight: bold;

  // @media (max-width: 600px) {
  //   font-size: 28px;
  // }

  @media (max-width: 600px) {
    font-size: 28px;
  }
}

.decimal-num-large {
  font-size: 30px;
  line-height: 0.8;
  opacity: 1;
  transition: opacity 0.6s;

  .hidden {
    opacity: 0;
  }

  // @media (max-width: 600px) {
  //   font-size: 22px;
  // }

  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 1;
    top: 2px;
  }
}

.whole-num {
  font-size: 30px;
  line-height: 1;
  font-weight: bold;

  // @media (max-width: 600px) {
  //   font-size: 22px;
  // }

  @media (max-width: 600px) {
    // font-size: 4vw;
    font-size: 16px;
    line-height: 1;
  }
}

.decimal-num {
  font-size: 18px;
  line-height: 1;
  opacity: 1;
  transition: opacity 0.6s;

  .hidden {
    opacity: 0;
  }

  // @media (max-width: 600px) {
  //   font-size: 16px;
  // }

  @media (max-width: 600px) {
    font-size: 10px;
  }
}

.amount {
  &>* {
    display: inline-block;
  }

  button {
    text-transform: none;
    font-size: 16px;

    @media (max-width: 600px) {
      font-size: 14px;
    }
  }
}

.gppt {
  width: 385px;
  height: 100px;
}

.pldg-akon {
  position: absolute;
  top: 0;
  right: 40px;
}

.textfield-block {
  position: relative;
  margin-top: 5px;

  .PrivateNotchedOutline-root-27 {
    top: 0;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .coin-logo {
    position: absolute;
    top: 40%;
    right: 10px;
    transform: translateY(-50%);
    display: block;
    width: 30px;
    height: 30px;
  }
}

.test-convertion {
  color: #A8A8A8;
  font-size: 14px;
  position: absolute;
  top: 30%;
  left: 22%;

  @media (max-width: 520px) {
    top: 105%;
    left: 0;
  }
}

.fiat-convertion {
  color: #016CE9;
  font-size: 14px;
  position: absolute;
  top: 30%;
  left: 22%;

  @media (max-width: 520px) {
    top: 105%;
    left: 0;
  }
}

.btns-container {
  gap: 10px
}

.footer-btns {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 8px;
}

.WA-container {
  margin-top: 30px;
}

.WA-text-style {
  display: flex;
  justify-content: space-between;
  // width: 124px;
  width: 100%;
  height: 31px;
  margin: 20px 4px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #111111;
}

.WA-text-container {
  display: flex;
  align-items: center;
}

.tooltip-span {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  color: #016CE9;
}

.submit-error {
  display: flex;
  justify-content: center;
}
.container-balance.update-container-balance {
  p.fiat-convertion.ace-convert-fiat {
    position: inherit;
    top: inherit;
    left: inherit;
    margin-top: 0;
}

label {
  margin-bottom: 10px;
}
}


.option-item-area-main-f {
  position: relative;

  .question-btn {
    background: transparent;
    border: none !important;
    // border: 1px solid #e0923f !important;
    // border-radius: 100%;
    width: 20px !important;
    // padding: 0;
    height: 20px;
    // font-size: 14px;
    // color: #e0923f !important;
    // font-weight: 700;
    position: absolute;
    left: calc(100% + 0px);
    top: 5px;
    margin-left: 10px !important;
  }
  .option-item-area {
    text-align: left;
    padding: 14px 24px;
  }
}

.disabled-with-info-btn {
  svg {
    width: 20px;
    margin-left: 5px;
  }
}
