.content.paypal.marginbot {
  >h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 29px;

    color: #464646;
  }
}

.paypal-market-item {
  width: calc(20% - 17px);
  border: 1px solid #e0e0e0;
  border-radius: 16px;
  text-align: center;
  padding: 35px 0 35px 0;
  min-width: 200px;

  img {
    margin-bottom: 20px;
  }

  p {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;

    text-align: center;

    color: #464646;
  }

  h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */

    text-align: center;

    color: #464646;
  }

  button {
    background-color: #0060ff;
    border-radius: 16px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #fff;
    border: none;
    padding: 10px 18px;

    &:hover {
      background-color: #0060ff;
    }
  }
}

.paypal-market-row {
  display: flex;
  // justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
}

.paypal-market-item-two {
  gap: 30px;
  width: calc(25% - 20px);
  position: relative;
  > img {
    width: 100%;
  }
}

.paypal-market-item-three {
  > img {
    width: 100%;
  }

}

.paypal-market-item-three {
  gap: 30px;
  width: calc(25% - 20px);
  position: relative;
  text-align: center;
  .paypal-market-item-content {
    .thumb-area {
      justify-content: center;
      margin-top: 30px;
    }
    .price-area {
      justify-content: center;
      flex-wrap: wrap;
      p {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
  
}

.paypal-market-four{
  .paypal-market-item-three{
    // border: 1px solid #D1D9E8;
    // height: 466px !important;
    border-radius: 10px;
    box-shadow: 0px 32px 39px 39px rgba(0, 0, 0, 0.02);
    h4{
      margin: 10px;
    }
    .thumb-area-main{
      // background: #4B6185;
      border-radius: 16px;
      padding: 20px 10px;
      color: #FFFFFF;
      .paypal-item-title-area{
        margin: 10px;
      }

      min-height: 190px;

      .feature-image{
        max-height: 220px;
        // max-width: 100%;
      }
      .thumb-area{
        img{
          width: 87px;
        }
      }
      .union-img{
        width: 60px;
        height: 15px;
      }
      .note{
        font-family: 'SF Pro Text';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
      }
    }
    .price-area{
      p{
        color: #1E1E1E !important;
        font-family: 'Gotham Rounded';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
        
          text-align: center;
      }
    }
    .add-to-cart-btn{
      background: #0060FF;
      border-radius: 16px;
      color: #FFFFFF;

    }
  }
    .Common .thumb-area-main {
      background: #4B6185;
    }
    .Rare .thumb-area-main {
      background: #9933FF;
    }
    .Legend .thumb-area-main {
      background: #FF39A2;
    }
    .Epic .thumb-area-main{
      background: #5583F9;
    }
}

.paypal-market-four .paypal-market-item-content {
  position: relative;
}
.paypal-market-row.paypal-market-two {
  gap: 20px;
  // justify-content: space-between;
  margin-bottom: 30px;
}
.paypal-market-item-content {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h5 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    margin: 0;
  }

  .thumb-area {
    display: flex;
    align-items: center;

    h4 {
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
      font-weight: 700;

      color: #FFFFFF;
      margin: 0;
      margin-left: 25px;

    }
  }

  .price-area {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      font-weight: 350;
      font-size: 18px;
      line-height: 22px;


      color: #FFFFFF;
    }

    button {
      background: #FFFFFF;
      border-radius: 12px;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      padding: 8px 20px;
      border: none;

      color: #0060FF;
      box-shadow: none;
    }
  }


  @media (max-width: 1600px) {
    padding: 12px;
    h5 {
      font-size: 16px;
      line-height: 17px;
    }

    .thumb-area {
     
      img {
        width: 30px;
      }
      h4 {
        font-size: 20px;
        line-height: 23px;  
      }
    }
  }

  @media (max-width: 1400px) {
    .price-area {
  
      p {
        font-weight: 350;
        font-size: 14px;
        line-height: 16px;
  
  
        color: #FFFFFF;
      }
  
      button {
        background: #FFFFFF;
        border-radius: 16px;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        padding: 4px 10px;
        border: none;
  
        color: #0060FF;
      }
    }
  }
}
.paypal-market-row.paypal-market-two.paypal-market-three {
  .price-area button {
    background: transparent;
    border: 1px solid #0060FF;
  }
  .paypal-market-item-content {
    padding: 0;
    border-radius: 16px;
  }

  .paypal-item-title-area {
    height: 22.5%;
    border-radius: 16px 16px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .thumb-area-main {
    height: 77.5%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 24px;
    border-radius: 0 0 16px 16px;
    h4, p {
      color: #1E1E1E;
    }
  }

  .paypal-market-item-three.Common {
    .paypal-item-title-area {
      background: #5D5D5D;
    }
    .paypal-market-item-content  {
      border: 1px solid #5D5D5D;

    }

    .thumb-area-main {
      background: #F6F6F6;
    }
  }

  .paypal-market-item-three.Rare {
    .paypal-item-title-area {
      background: #1070FF;
    }
    .paypal-market-item-content  {
      border: 1px solid #D2D2D2;

    }

    .thumb-area-main {
      background: #EDF4FF;
    }
  }


  .paypal-market-item-three.Epic {
    .paypal-item-title-area {
     
      background: #FF0D90;
    }
    .paypal-market-item-content  {
      border: 1px solid #FFB8DE;

    }
    .thumb-area-main {
      background: #F7EFFF;
    }
  }

  .paypal-market-item-three.Legend {
    .paypal-item-title-area {
      background: #860CFF;
    }
    .paypal-market-item-content  {
      border: 1px solid #DFBFFE;

    }
    .thumb-area-main {
      background: #F5ECFF;
    }
  }


}


.success-container{
  width: 675px;
  background: #FFFFFF;
  min-height: 500px;
  margin: 20px auto;
  box-shadow: 0px 32px 39px 32px rgba(0, 0, 0, 0.04);
  border-radius: 24px;
  text-align: center;
  padding: 85px 65px 55px 65px;

  .header-img img{
    height: 66.67px;
    width: 66.67px;
  }
  
  .title{
    font-style: normal;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #464646;
  }
  .order-container{
    border: 1px solid #D9D9D9;
    border-radius: 24px;
    text-align: left;
    padding: 20px 40px;
    margin-top: 20px;
    h4{
      font-style: normal;
      font-size: 18px;
      line-height: 22px;
      color: #464646;
    }

    .summery{
      margin-bottom: 15px;
    }
    .orderid-container{
      h5{
        margin: unset;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #464646;
        width: 50%;
      }
      display: flex;
      .date{
        text-align: right;
      }
    }
    thead{
      // color: #9CAEC4;
      tr{
        border-bottom: 1px solid #D9D9D9;

      }
    }

    tbody{
      .item-tr{
        padding: 10px 0px;
        // border-bottom: 1px solid #000;
        &:last-child{
          border-bottom: none;
        }
      }
      .total-tr{
        border: none;
        &.grand td{
          font-weight: 700;
          font-size: 18px;
        }
      }

      td{

        font-size: 14px;
        line-height: 19px;
        font-weight: var(--p-weight);
      }
    }
  }

  .btn-wrapper{
    margin-top: 55px;
    text-align: center;

    .backtodash-btn{
      width: 182px;
      margin: 0px auto;
    }
  }

}
@media (max-width: 1280px) {
  .paypal-market-item-two  {
    width: 202px;
   
  }
  .paypal-market-item-three {
    width: 202px;
   
  }
  .paypal-market-row.paypal-market-two {
    justify-content: flex-start;

  }
 }