@import "../../../layout/mixins";
@import "../../../layout/variables";

.package-content {
  padding: $px-30;
  height: 100%;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-items: center;

  .empty-package{
    text-align: center;
    margin: 0;
  }
  .card-columns {
    // @include media-breakpoint-only(600px) {
    //   column-count: 2;
    // }
    // @include media-breakpoint-only(xl) {
    //   column-count: 5;
    // }
    @media (max-width: 600px) {
      column-count: 2;
    }
  }

  .card {
    text-align: center;
    padding: 0;

    .card-body {
      padding: 1.25rem;
    }
  }

  .mobile-view {
    .package-content {
      padding: $px-20 $px-24 $px-50;
      padding-top: 10rem;
      min-height: calc(100vh - 140px);
    }

    @media (max-width: 600px) {
      .package-content {
        padding: $px-24 $px-16;
        padding-top: 10rem;
        font-size: $px-14;
        min-height: calc(100vh - 140px);
      }
    }
  }
  .loader {
    height: 50px;
    width: auto;
    animation: spin 1.2s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}