@import "../../layout/mixins";
@import "../../layout/variables";

.custom-dialog {
  .paper-root {
    box-shadow: 3.5px 3.5px 10px 0 rgba(7, 6, 6, 0.3);
    border-radius: 10px;
    width: 100%;
  }

  .MuiDialogTitle-root {
    text-align: center;
    border-bottom: 1px solid darken($white, 20%);
    color: $text-shade-2;

    h2 {
      font-size: 24px;
    }
  }

  .MuiDialogContent-root {
    padding: 30px 50px 2px;
    overflow-y: unset;
  }

  .MuiDialogActions-root {
    padding: 30px 50px;
    display: block;

    > :not(:first-child) {
      margin: 0;
    }
  }

  @media screen and (min-width:481px) and  (max-width: 768px) {
    .MuiDialog-paperWidthSm.MuiDialog-paperScrollBody {
      max-width: 100%;
    }

    .MuiDialog-paper {
      margin: 0;
      width :428px;
      height : 445px;
    }

    .MuiDialogTitle-root {
      padding: 16px 16px 12px;

      h2 {
        font-size: 18px;
      }
    }

    .MuiDialogContent-root {
      padding: 22px 30px 2px;
    }

    .MuiDialogActions-root {
      padding: 22px 30px 30px;
    }
  }
}

@media screen and (min-width:320px) and  (max-width: 480px) {
  .MuiDialog-paperWidthSm.MuiDialog-paperScrollBody {
    max-width: 100%;
  }

  .MuiDialog-paper {
    margin: 0;
    width : 342px;
    height : 459px;
  }

  .MuiDialogTitle-root {
    padding: 16px 16px 12px;

    h2 {
      font-size: 18px;
    }
  }

  .MuiDialogContent-root {
    padding: 22px 30px 2px;
  }

  .MuiDialogActions-root {
    padding: 22px 30px 30px;
  }
}