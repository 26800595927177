@import "../../../layout/mixins";
@import "../../../layout/variables";

.my-dappx-page {
  padding: 2rem;
  .token-details {
    .details-text-container {
      position: inherit;
      top: inherit;
    }
  }
  .amount {
    color: #111111;

    > :first-child {
      display: inline-block;
      font-weight: bold;
    }
    > :last-child {
      display: inline-block;
    }
  }
  .bold {
    font-weight: 700;
  }

  .dappx-details-icon {
    height: 51px;
    width: auto;
    z-index: 1;
    position: relative;
    left: calc(100% - 3rem);
    
    @media (max-width: 960px) {
      height: 35px;
      position: relative;
      left: calc(100% - 2.5rem);
    }
    
    @media (max-width: 600px) {
      height: 35px;
      position: relative;
      left: calc(100% - 2rem);
    }
  }

  .detail {
    padding: 1.5rem 2.25rem;
    background-color: #F7F7F7;
    border-radius: .5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    svg {
      filter: invert(73%) sepia(25%) saturate(534%) hue-rotate(180deg) brightness(103%) contrast(102%);
    }
    
    @media (max-width: 960px) {
      padding: 1.5rem .75rem;
    }
    
    @media (max-width: 600px) {
      padding: .5rem;
    }
  }

  .growth-detail {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;

    @media (max-width: 600px) {
      .details-text-container {
        top: 0.75rem;
      }
  
      .amount {
        padding: 0;
      }
    }
  }

  .growth-img {
    @media (max-width: 960px) {
      height: 35px;
      width: auto;
    }
  }

  .details-text-container {
    z-index: 2;
    position: absolute;
    top: 1rem;
    
    @media (max-width: 600px) {
      top: .25rem;
    }
  }

  .growth-container {
    background-color: #006CEA;
    padding: .75rem;
    border-radius: .5rem;
    height: 100%;
    left: calc(100% - 4.75rem);

    @media (max-width: 960px) {
      left: calc(100% - 4rem);
    }
  }
  .blue {
    background-color: #E5F0FD;
    h4{
      color: $blue;
    }
  }
  .gray {
    background-color: #F7F7F7;
  }
  .buttons-block {
    display: flex;
    width: 100%;
    button:disabled {
      color: rgba(0, 0, 0, 0.26);
      border: 1px solid rgba(0, 0, 0, 0.12);
    }
    .MuiButton-root {
      flex: 1;
      font-weight: 400;
      font-size: $px-16;
      line-height: 1.6;
      border-radius: 5px;
      color: $blue;
      border-color: $blue;
      font-weight: 500;
      margin: 5px;
    }
  }

  .total-balance-label {
    color: #111111;
    font-weight: bold;
    line-height: 1;

    @media (max-width: 600px) {
      font-size: 20px;
    }
  }

  .whole-num-large {
    color: #111111;
    font-size: 50px;
    top: 0.25rem;
    line-height: 1;
    font-weight: bold;
  
    @media (max-width: 600px) {
      font-size: 28px;
    }
  }
  
  .decimal-num-large {
    color: #111111;
    font-size: 30px;
    line-height: 0.8;
    
    @media (max-width: 600px) {
      font-size: 16px;
      line-height: 1;
      top: 2px;
    }
  }
  
  .whole-num {
    color: #111111;
    font-size: 30px;
    line-height: 1;
    font-weight: bold;
    
    @media (max-width: 600px) {
      font-size: 16px;
      line-height: 1;
    }
  }
  
  .decimal-num {
    color: #111111;
    font-size: 18px;
    line-height: 1;
    
    @media (max-width: 600px) {
      font-size: 10px;
    }
  }

  .details-title {
    font-size: 20px;
    line-height: 1.2;
    color: #626262;
    font-weight: normal;
    
    @media (max-width: 600px) {
      font-size: 12px;
    }
  }

  @media (max-width: 960px) {
    padding-top: 5rem;
  }

  @media (max-width: 670px) {
    padding: 5rem .5rem;

    .buttons-block {
      .MuiButton-root {
        font-size: $px-12;
        margin: 3px;
  
        &:first-child{
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}