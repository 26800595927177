@import "../../layout/mixins";
@import "../../layout/variables";
.top-nav-area {
  .MuiAppBar-root {
    background-color: $white;
    border-bottom: 1px solid #ccc;
    transition: background-color 0.4s ease, border-color 0.4s ease;
    .MuiButton-root {
      text-transform: unset;
      color: $text-shade-2;
      font-size: $px-16;
      font-weight: $font-weight-r;
      transition: color 0.4s ease;
      &:hover {
        background-color: transparent !important;
        // font-weight: 700;
        color: #0060FF;
      }
    }
  }
  .MuiPaper-elevation4 {
    box-shadow: none;
  }
  .MuiButton-text {
    padding: 2px 0;
    min-width: inherit;
    margin-left: 5vw;
  }
  .container {
    padding: 0;
  }
  .logo-block {
    display: inline-block;
    // margin-right: 6rem;
    max-width: 190px;
    padding-top: 1px;
    @include remove-highlight;
    &::selection {
      color: none;
      background: none;
    }
    &::-moz-selection {
      color: none;
      background: none;
    }
    svg {
      width: 100%;
      vertical-align: middle;
      @include remove-highlight;
      &.dappstore-logo .st16 {
        transition: fill 0.4s ease;
      }
    }
    @media (max-width: 899px) {
      margin-right: 0;
      max-width: 120px;
    }
  }
  .right-block {
    // margin-left: auto;
    display: flex;
    align-items: center;
    gap: 22px;
    .MuiIconButton-root {
      padding: 11px;
    }
    .user-btn,
    .searchIcon,
    .download-btn {
      svg {
        width: $px-20;
        height: $px-20;
        fill: $black;
        transition: fill 0.4s ease;
      }
    }
  }
  .user-mobile,
  .user-btn {
    position: relative;
    &:after {
      content: "Connect";
      position: absolute;
      background: $red;
      color: $white;
      padding: 3px 4px 2px;
      font-size: $px-10;
      line-height: 1;
      border-radius: 4px;
      top: -7px;
    }
    &:before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid $red;
      top: 8px;
    }
    &.connected {
      &:after,
      &:before {
        content: none;
      }
    }
  }
  .user-mobile {
    &:after {
      top: -4px;
    }
    &:before {
      top: 10px;
    }
  }
  .language {
    display: inline-block;
    padding: 6px;
  }
  .search {
    display: inline-block;
    position: relative;
    margin-left: 2px;
    border-radius: 4px;
    background-color: transparent;
    width: auto;
    &.focus {
      background-color: rgba(0, 0, 0, 0.04);
      .inputInput {
        width: 10rem;
        padding-left: $px-36;
      }
    }
    .searchIcon {
      height: 100%;
      display: flex;
      padding: 0px 7px;
      position: absolute;
      align-items: center;
      pointer-events: none;
      justify-content: center;
      svg {
        transition: fill 0.4s ease;
      }
    }
    .inputInput {
      padding: 6px 10px 6px 34px;
      font-size: $px-14;
      line-height: 1.75;
      color: $text-shade-2;
      transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 0.4s ease;
      width: 0;
      height: auto;
      &:focus {
        width: 10rem;
        padding-left: $px-36;
      }
    }
  }
  .hamburger,
  .search-mobile,
  .user-mobile {
    svg {
      fill: $black;
      transition: fill 0.4s ease;
    }
  }
  .hamburger,
  .search-mobile,
  .user-mobile {
    svg {
      width: $px-20;
      height: $px-20;
      fill: $black;
    }
  }
  &.transparent-bg {
    .MuiAppBar-root {
      background-color: transparent;
      border-color: transparent;
      .MuiButton-root {
        color: $white;
      }
    }
    .logo-block svg.dappstore-logo .st16 {
      fill: $white;
    }
    .right-block .download-btn svg,
    .right-block .user-btn svg {
      fill: $white;
    }
    .search {
      &.focus {
        background-color: rgba(0, 0, 0, 0.4);
      }
      .searchIcon {
        svg {
          fill: $white;
        }
      }
      .inputInput {
        color: $white;
        font-weight: $font-weight-l;
      }
    }
    .hamburger,
    .search-mobile,
    .user-mobile {
      svg {
        fill: $white;
      }
    }
  }
}
// Dropdown Menu =================
.dropdown_menu {
  display: inline-block;
  .MuiButton-root:hover {
    background-color: transparent !important;
  }
  .MuiListItem-root {
    padding-left: 22px;
    padding-right: 22px;
    a {
      text-decoration: none;
      color: $text-shade-2;
      display: block;
      font-size: $px-14;
      width: 100%;
      text-align: center;
    }
    &:hover a {
      color: #016CE9;
      background-color: transparent;
    }
  }
  .MuiListItem-button:hover {
    background-color: transparent;
  }
  .dd-list {
    padding-top: 17px;
    border-radius: 0;
    .MuiPaper-root {
      box-shadow: 0 4px 12px rgba($black, 0.16);
    }
  }
  .learn-btn {
    position: relative;
    // &:after {
    //   content: "Click Here";
    //   position: absolute;
    //   background: $red;
    //   color: $white;
    //   padding: 3px 4px 2px;
    //   font-size: $px-10;
    //   line-height: 1;
    //   border-radius: 4px;
    //   top: -12px;
    // }
    // &:before {
    //   content: "";
    //   position: absolute;
    //   width: 0;
    //   height: 0;
    //   border-left: 5px solid transparent;
    //   border-right: 5px solid transparent;
    //   border-top: 5px solid #464646;
    //   top: 50%;
    //   right: 10px;
    //   transform: translateY(-10%);
    // }
  }
}
// Mobile Modal ==================
.hamburger-content {
  .MuiBackdrop-root {
    background-color: transparent;
  }
  >.MuiDrawer-paper {
    width: 100%;
    height: calc(100% - 54px);
    top: 65px;
    background-color: darken($white, 3%);
    @media (max-width: 600px) {
      top: 57px;
    }
  }
  >.MuiPaper-elevation16 {
    box-shadow: none;
  }
  .MuiButtonBase-root {
    color: inherit;
    text-decoration: none;
    background-color: $white;
    border-bottom: 1px solid darken($white, 9%);
    text-align: left;
    justify-content: flex-start;
    font-weight: 300;
    width: 100%;
    overflow: hidden;
    font-size: $px-16;
    min-height: 52px;
    line-height: 1.5;
    padding: 6px 16px;
    white-space: nowrap;
    box-sizing: border-box;
    &:hover {
      text-decoration: none;
      background-color: rgba(0, 0, 0, 0.04);
      color: $red;
    }
    &:disabled {
      cursor: default;
    }
  }
  .language-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .collapsible {
    .collapsible-btn {
      margin: 0;
      >.MuiButtonBase-root {
        position: relative;
        svg {
          position: absolute;
          right: 8px;
          width: $px-18;
          height: $px-18;
        }
      }
      &.active {
        >.MuiButtonBase-root {
          background: $red;
          color: $white;
        }
      }
    }
    .collapsible-list {
      border-bottom: 1px solid darken($white, 9%);
      padding: 8px 0;
      .MuiButtonBase-root {
        background: darken($white, 3%);
        padding: 0 16px;
        min-height: 36px;
        border-bottom: none;
        &:hover {
          text-decoration: none;
          background-color: rgba(0, 0, 0, 0.04);
        }
      }
    }
  }
}
.search-mobile-content {
  .MuiBackdrop-root {
    background-image: linear-gradient(rgba(0, 0, 0, 0) 66px,
        rgba(0, 0, 0, 0.5) 66px,
        rgba(0, 0, 0, 0.5));
    background-color: transparent;
  }
  .MuiDrawer-paper {
    top: 65px;
    background-color: #f5f5f5;
    @media (max-width: 600px) {
      top: 57px;
    }
  }
  .MuiPaper-elevation16 {
    box-shadow: 0px 34px 40px 0px rgba(0, 0, 0, 0.05);
  }
  .search {
    display: inline-block;
    position: relative;
    margin-left: 0;
    border-radius: 4px;
    background-color: $white;
    width: auto;
    &.focus {
      background-color: $white;
    }
    .searchIcon {
      height: 100%;
      display: flex;
      padding: 0px 10px;
      position: absolute;
      align-items: center;
      pointer-events: none;
      justify-content: center;
      svg {
        width: $px-20;
        height: $px-20;
        fill: $black;
        transition: fill 0.4s ease;
      }
    }
    .inputRoot {
      width: 100%;
    }
    .inputInput {
      padding: 20px 10px 20px 34px;
      padding-left: 2.6rem;
      letter-spacing: 1px;
      font-size: $px-16;
      line-height: 1.75;
      color: $text-shade-2;
      width: 100%;
    }
  }
}
// Connect Wallet Component ======
.connect-wallet-component {
  display: inline-block;
  .connect-wallet-menu {
    padding-top: 12px;
    border-radius: 0;
    .MuiPaper-root {
      box-shadow: 0 4px 12px rgba($black, 0.16);
    }
    .MuiListItem-root {
      padding-left: 22px;
      padding-right: 22px;
      text-decoration: none;
      color: $text-shade-2;
      display: block;
      font-size: $px-14;
      width: 100%;
      text-align: center;
      min-height: auto;
      &:hover {
        color: $red;
      }
    }
    .MuiListItem-button:hover {
      background-color: transparent;
    }
  }
  @media (max-width: 600px) {
    display: none;
    .connect-wallet-menu {
      padding-top: 7px;
      .MuiListItem-root {
        padding-left: 18px;
        padding-right: 18px;
      }
    }
  }
}
// .update-download-btn {
  // background: #016CE9;
  // border-radius: 16px;
  // padding: 10px 16px;
  // font-style: normal;
  // font-size: 14px;
  // line-height: 1;
  // color: #FFFFFF;
  // display: flex;
  // align-items: center;
  // border: none;
  // gap: 15px;
  // font-weight: 500;
  // svg {
  //   width: 20px;
  // }
  // svg path,
  // polygon,
  // rect {
  //   fill: #fff;
  // }
  // &:hover {
  //   color: #fff;
  // }
// }
.connet_wallet_btn {
  background: transparent;
  border: none;
  // font-family: 'Gotham Rounded';
  font-style: normal;
  font-weight: 350;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  color: #016CE9;
}
.user-btn.connected {
  background: #ECEFF2;
  border-radius: 16px;
  padding: 10px 12px 10px 10px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  // width: 136px;
  border: none;
  p {
    color: #464646;
    font-size: 14px;
    line-height: 15px;
    font-weight: 500;
  }
}
.user-dropdown-menu {
  position: absolute;
  right: -300px;
  top: 10px;
  width: 286px;
  background: #FFFFFF;
  box-shadow: 0px 0px 128px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  padding: 16px 16px 20px 16px;
  transition: all ease-in .3s;
  z-index: 999;
  ul {
    padding: 0;
    a {
      display: flex;
      gap: 20px;
      padding: 12px 20px;
    border-radius: 20px;
    }
  }
}
.user-dropdown-menu.open-menu {
  right: 10px;
}
.user-dropdown-header {
  display: flex;
  justify-content: space-between;
align-items: center;
  margin-bottom: 15px;
}
.transparent-btn {
  background-color: transparent;
  border: none;
}
.dropdown-header-keft {
  display: flex;
  align-items: center;
  gap: 8px;
  p {
    color:  #4F4F4F;
    font-size: 18px;
  }
}
.dis-btn {
  background: #F4F4F4;
border-radius: 16px;
color: #1E1E1E;
font-weight: 500;
font-size: 14px;
line-height: 17px;
text-align: center;
border: none;
width: 100%;
padding: 12px;
margin-top: 20px;
}
.close-dropdown-btn {
  width: 40px;
  height: 40px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
}
.custom-toolbar {
  position: inherit !important;
  display: flex;
  justify-content: space-between;
  min-height: 87px !important;
  padding-left: 34px !important;
    padding-right: 34px !important;
}
.user-mobile.connected {
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  p {
    display: none;
  }
}
.mobile-box-area {
  display: flex;
  align-items: center;
}
.mobile-box {
  display: flex;
  align-items: center;
}

.lang {
  width: 20px;
  position: relative;
  height: 60px;
  display: flex;
  img {
    min-width: 20px;
  }

  &:hover {
    ul {
      right: -31px;
    }
  }

  ul {
    position: absolute;
    list-style: none;
    background-color: #fff;
    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
    right: -250px;
    padding: 10px 25px;
    top: 100%;
    text-align: center;
    li {
      cursor: pointer;
      font-size: 14px;
    }
  }
}

.lang-active {
  display: flex;
  gap: 5px;
  align-items: center;

  span {
    line-height: 1;
  }
}