@import "../../layout/mixins";
@import "../../layout/variables";

.terms-content{
  padding-top: $px-50;
  padding-bottom: $px-80;
  h1{
    color: lighten($black, 7%);
    margin: 0 0 $px-2;
    font-size: $px-34;
  }
  h2{
    color: lighten($black, 7%);
    margin: 0 0 $px-20;
    font-size: $px-20;
  }
  h3{
    margin: $px-50 0 $px-2;
    font-size: $px-16;
  }
  p, ul{
    margin: 0;
    line-height: 1.6;
  }
  p{
    text-align: justify;
    text-justify: inter-word;
  }

  @media (max-width: 600px) {
    padding-top: $px-22;
    padding-bottom: $px-40;
    h1{
      margin: 0 0 $px-2;
      font-size: $px-22;
    }
    h2{
      margin: 0 0 $px-16;
      font-size: $px-14;
    }
    h3{
      margin: $px-30 0 $px-2;
      font-size: $px-12;
    }
    ul{
      padding-left: $px-24;
    }
    p, ul{
      font-size: $px-12;
    }
  }
}