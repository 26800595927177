// Color
$black: #000;
$white: #fff;
$light-pink: #fd748e;
$light-orange: #f79679;
$sky-blue: #1e8bd1;
$sky-blue-2: #00b8d0;
$sky-blue-3: #00dbff;
$blue: #016ce9;
$blue-2: #3542d8;
$red: #ed1f24;
$avocado: #478600;
$light-purple: #bdc0e6;

// Shade
$text-shade-1: #363636;
$text-shade-2: #464646;
$text-shade-3: #898989;
$text-shade-4: #acacac;
$text-shade-5: #c2c2c2;

// Default
$roboto: "Roboto", sans-serif;

// PX to Rem
$px-2: 0.125rem;
$px-4: 0.25rem;
$px-6: 0.375rem;
$px-8: 0.5rem;
$px-10: 0.625rem;
$px-12: 0.75rem;
$px-14: 0.875rem;
$px-16: 1rem;
$px-18: 1.125rem;
$px-20: 1.25rem;
$px-22: 1.375rem;
$px-24: 1.5rem;
$px-26: 1.625rem;
$px-28: 1.75rem;
$px-30: 1.875rem;
$px-32: 2rem;
$px-34: 2.125rem;
$px-36: 2.25rem;
$px-38: 2.375rem;
$px-40: 2.5rem;
$px-42: 2.625rem;
$px-44: 2.75rem;
$px-46: 2.875rem;
$px-48: 3rem;
$px-50: 3.125rem;
$px-60: 3.75rem;
$px-70: 4.375rem;
$px-80: 5rem;

// Font Weight
$font-weight-l: 300;
$font-weight-r: 400;
$font-weight-m: 500;
$font-weight-b: 700;
$font-weight-bb: 900;
