@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

@mixin horizontal-list {
  @include reset-list;
  li {
    display: inline-block;
  }
}

@mixin remove-highlight {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

@mixin custom-error {
  .custom-error {
    background: none;
    color: $red;
    font-size: $px-16;
    text-align: center;
    padding: 0;
    .MuiAlert-icon {
      display: none;
    }
    .MuiAlert-message {
      display: flex;
      justify-content: center;
      margin: 0 auto;
      font-weight: 300;
      font-size: $px-14;
      margin-bottom: $px-6
    }
    .icon {
      fill: $red;
      width: 22px;
      height: 20px;
      margin-right: $px-8;
      min-width: 22px;
    }
  }
}