@import '../../layout/variables';
@import '../../layout/mixins';

.loader{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &.appear{
    position: absolute;
  }

  .loader-icon{
    margin: 0 auto;
  }

  &.colored-bg{
    .loader-icon{
      circle{
        fill: $white;
      }
    }
  }
}