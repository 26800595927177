@import "../../layout/mixins";
@import "../../layout/variables";

.footer-area {
  background-color: #363636;
  padding: $px-30 0;
  font-size: $px-14;
  color: $white;

  .sp-mode {
    display: none;
  }

  .row {
    display: flex;

    .col {
      width: 33.3333%;
    }

    .title {
      font-size: $px-20;
      margin: 0 0 $px-24;
    }

    .website-list {
      @include reset-list;
      display: inline-block;
      li.copyright {
        color: #b7b9c5;
      }
      li {
        margin-bottom: $px-8;
        .copyright {
          color: #b7b9c5;
        }
        &:last-child() {
          margin-bottom: 0;
        }

        span {
          font-weight: $font-weight-b;
          color: $white;
        }

        a {
          
          color: #b7b9c5;
        }
      }
    }

    .logo-block {
      .logo {
        display: block;
        margin-top: .2rem;
        margin-bottom: $px-26;
        svg {
          vertical-align: middle;
          width: 292px;
        }
        img {
          vertical-align: middle;
          width: 292px;
        }
      }

    }

    .contact-block {
      padding-left: 1.2rem;
      .title {
        // text-transform: uppercase;
      }

      @media (max-width: 899px) {
        margin-right: 0;
      }

      @media (max-width: 767px) {
        min-width: auto;
      }

      .contact-list {
        @include reset-list;

        li {
          margin-bottom: $px-8;

          &:last-child() {
            margin-bottom: 0;
          }

          span {
            font-weight: $font-weight-b;
            vertical-align: top;
            color: $white;
          }

          a {
            color: #b7b9c5;
          }
        }
      }
    }

    .subscribe-block {
      padding-left: 1.2rem;
      .desc {
        line-height: 1.6;
        margin-bottom: $px-20;
      }

      .subscribe-form {
        display: flex;
        align-items: self-start;

        .MuiFormControl-root {
          width: 100%;
          padding-right: $px-16;

          .MuiInputLabel-formControl {
            top: -9px;

            &.MuiInputLabel-outlined.MuiInputLabel-shrink {
              top: 0;
            }
          }

          .MuiOutlinedInput-input {
            padding: $px-10 $px-14;
            font-size: $px-14;
            color: $text-shade-1;
            color: $white;
            height: 36px;

            @media (max-width: 600px) {
              font-size: $px-12;
            }
          }
          .MuiOutlinedInput-root {
            .MuiOutlinedInput-notchedOutline {
              border: solid 1px #707070;
            }
            &.Mui-focused {
              .MuiOutlinedInput-notchedOutline {
                border: solid 2px $black;
              }
            }
            &.Mui-error {
              &.Mui-focused .MuiOutlinedInput-notchedOutline,
              .MuiOutlinedInput-notchedOutline {
                border-color: #f44336;
              }
            }
          }
          &.successRoot {
            .MuiOutlinedInput-root {
              &.Mui-focused .MuiOutlinedInput-notchedOutline,
              .MuiOutlinedInput-notchedOutline {
                border-color: green;
              }
            }
            .MuiFormLabel-root,
            .MuiFormHelperText-root {
              color: green;
            }
          }
        }

        .btn-wrapper {
          position: relative;

          .MuiButton-root {
            padding: $px-12 $px-40 $px-10;
            line-height: 1;
            background: $black;
            font-weight: 400;

            .MuiButton-label {
              color: $white;

              @media (max-width: 600px) {
                font-size: $px-12;
              }
            }

            &.MuiButton-contained.Mui-disabled {
              box-shadow: none;
              background-color: rgba(0, 0, 0, 0.4);
              .MuiButton-label {
                color: rgba(255, 255, 255, 0.3);
              }
            }
          }
          .loader {
            color: green;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -10px;
            margin-left: -12px;
          }
        }

        @media (max-width: 375px) {
          .MuiFormControl-root{
            padding-right: .6rem;
          }
          .btn-wrapper .MuiButton-root{
            padding: $px-12 $px-22 $px-10;
          }
        }
      }

      .social-links{
        @include horizontal-list;
        margin-top: $px-18;

        li{
          margin-right: $px-10;
          opacity: 1;
          transition: opacity .2s ease-in-out;

          img,
          a{
            vertical-align: middle;
          }

          img{
            width: 34px;
          }

          &:hover{
            opacity: 0.6;
          }
        }
      }
    }
  }

  @media (max-width: 1120px) {
    .row {
      .col {
        width: 30%;
      }
      .contact-block {
        padding-left: 0;
      }

      .subscribe-block {
        width: 40%;
      }
    }
  }

  @media (max-width: 959px) {
    .sp-mode {
      display: block;
    }

    .row {
      flex-direction: column-reverse;

      .col,
      .contact-block,
      .subscribe-block {
        width: 100%;
        padding-left: 0;
        margin-bottom: $px-30;
      }
      .contact-block {
        margin-bottom: 0;
        .contact-list {
          margin-bottom: 0;
          .sp-mode{
            margin-top: $px-36;
            margin-bottom: 0;
          }
        }
      }
      .logo-block {
        display: none;
      }
    }

    .copyright{
      margin: 0;
    }
  }

  @media (max-width: 600px) {
    font-size: $px-12;

    .row {
      .title {
        font-size: $px-16;
        margin: 0 0 $px-14;
      }

      .subscribe-block{
        margin-bottom: $px-28;
      }

      .subscribe-block .desc{
        margin: 0 0 $px-10;
      }
    }
  }
}
