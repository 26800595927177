@import "../../layout/mixins";
@import "../../layout/variables";

.game-content{
  background-color: $white;

  .section{
    padding: $px-44 0;

    .section-title{
      font-size: $px-28;
      font-weight: $font-weight-bb;
      color: $black;
      margin-bottom: $px-40;
      text-align: center;

      @media (max-width: 600px) {
        font-size: $px-26;
        margin-bottom: $px-30;
      }
    }

    .hastag-box{
      text-align: center;
      margin-top: $px-40;

      .box-wrap{
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-color: #0051b0;
        padding: $px-32 $px-14;
        color: $white;
        font-weight: $font-weight-m;
        font-size: $px-24;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        border-radius: $px-10;
      }

      .box-racing{
        background-image: url(../../assets/images/page-games/special/hashtag-racing.jpg);
      }

      .box-adventure{
        background-image: url(../../assets/images/page-games/special/hashtag-adventure.jpg);
      }

      .box-casual{
        background-image: url(../../assets/images/page-games/special/hashtag-casual.jpg);
      }

      .box-action{
        background-image: url(../../assets/images/page-games/special/hashtag-action.jpg);
      }

      .box-indie{
        background-image: url(../../assets/images/page-games/special/hashtag-indie.jpg);
      }

      .box-simulation{
        background-image: url(../../assets/images/page-games/special/hashtag-casual.jpg);
      }

      @media (max-width: 959px) {
        .box-wrap{
          padding: $px-26 $px-14;
          font-size: $px-22;
        }
      }

      @media (max-width: 600px) {
        margin-top: $px-30;

        .box-wrap{
          padding: $px-18 $px-10;
          font-size: 3.3vw;
        }
      }
    }

    @media (max-width: 600px) {
      padding: $px-40 0;
      
      .hastag-box{
        margin-top: $px-16;
      }
    }
  }

  .section-slider{
    padding-top: 0;
    text-align: center;
    position: relative;

    .img-full{
      width: 100%;
      max-width: none;
    }

    .bg-slide{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc( 100% - 50px );
      overflow: hidden;
    }

    .content-slide{
      div{
        outline: none;
      }

      .wrapper-slide{
        display: flex;
        align-items: center;
        height: 100%;
        height: 48vw;
        position: relative;
        max-width: 1300px;
        margin: 0 auto 0 auto;
        .left{
          width: 60%;
          img{
            position: relative;
            left: 20px;
          }
        }

        .right{
          text-align: left;
          width: 40%;

          img{
            max-width: 340px;
            max-height: 120px;
          }

          p{
            color: $white;
            font-size: $px-30;
            max-width: 500px;
            margin: $px-60 0 $px-40;
          }

          .go-to-play{
            padding: $px-18 $px-40;
            border-radius: $px-10;
            font-size: $px-18;
            text-transform: uppercase;
            color: $white;
          }
        }
      }

      .slick-slide{
        .wrapper-slide{
          margin: 0 0 0 auto;
          opacity: .3;
          transition: margin 0.4s linear, opacity 0.4s linear, left 0.4s linear;
          transition-delay: 0.6s;
        }
      }

      .slick-slide.slick-cloned.slick-center{
        .wrapper-slide{
          margin: 0 auto 0 auto;
          opacity: 1;
          left: auto;
          transition-delay: 0;
        }
      }

      .slick-active.slick-slide{
        .wrapper-slide{
          margin: 0 auto 0 auto;
          opacity: 1;
          left: auto;

          .right{
            display: block;
          }
        }
      }

      .slick-active + .slick-slide{
        .wrapper-slide{
          margin: 0 auto 0 0;
          left: -30px;

          .right{
            display: none;
            width: 40%;
          }
        }
      }

      .slick-dots{
        bottom: 6%;

        li {
          height: 5px;
          width: 50px;
          transition: width .2s linear;
          margin: 0 4px;

          button{
            height: 5px;
            width: 50px;
            transition: width .2s linear;

            &:before{
              content: '';
              height: 5px;
              width: 50px;
              background: $white;
              transition: width .2s linear;
            }
          }
          &.slick-active,
          &.slick-active button,
          &.slick-active button:before{
            width: 100px;
            top: -6px;
          }
        }
      }
    }

    @media (max-width: 1680px) {
      .content-slide{
        .wrapper-slide{
          max-width: 1230px;
        }
      }
    }

    @media (min-width: 960px) and (max-width: 1500px) {
      .content-slide{
        .wrapper-slide{
          max-width: 960px;

          .right{
            padding-right: $px-40;
            img{
              max-width: 250px;
              max-height: 100px;
            }

            p{
              font-size: $px-22;
              margin: $px-50 0 $px-40;
            }
  
            .go-to-play{
              padding: $px-16 $px-30 $px-14;
              font-size: $px-16;
            }
          }
        }

        .slick-dots{
          li {
            height: 4px;
            width: 30px;
  
            button{
              height: 4px;
              width: 30px;
  
              &:before{
                height: 4px;
                width: 30px;
              }
            }
            &.slick-active,
            &.slick-active button,
            &.slick-active button:before{
              width: 70px;
            }
          }
        }
      }
    }

    @media (min-width: 960px) and (max-width: 1280px) {
      .content-slide{
        .wrapper-slide{
          max-width: 768px;
          height: 55vw;

          .right{
            img{
              max-width: 150px;
              max-height: 80px;
            }

            p{
              font-size: $px-18;
              margin: $px-36 0 $px-30;
            }
  
            .go-to-play{
              padding: $px-14 $px-30 $px-12;
              font-size: $px-14;
            }
          }
          .left{
            img{
              left: 10px;
            }
          }
        }

        .slick-active + .slick-slide{
          .wrapper-slide{
            left: -20px;
          }
        }

        .slick-dots{
          li {
            height: 3px;
            width: 30px;
  
            button{
              height: 3px;
              width: 30px;
  
              &:before{
                height: 3px;
                width: 30px;
              }
            }
            &.slick-active,
            &.slick-active button,
            &.slick-active button:before{
              width: 50px;
              top: -5px;
            }
          }
        }
      }
    }

    @media (max-width: 959px){
      padding-bottom: 50px;

      .bg-slide{
        height: 100%;
        overflow: visible;

        img{
          width: 125%;
        }
      }
      
      .content-slide{
        .wrapper-slide{
          height: 69vw;
          .left{
            display: none;
          }

          .right{
            width: 80%;
            margin: 0 auto;
            padding: 30px 0;
            position: relative;
            top: 8%;

            img{
              max-width: 26vw;
              max-height: 10vw;
            }

            p{
              max-width: 60%;
              font-size: 2.8vw;
              margin: 6vw 0;
            }

            .go-to-play{
              padding: 2vw 4.4vw;
              border-radius: 1vw;
              font-size: 2vw;
            }
          }
        }

        .slick-dots{
          bottom: -50px;
  
          li {
            height: 4px;
            width: 30px;
  
            button{
              height: 4px;
              width: 30px;
  
              &:before{
                height: 4px;
                width: 30px;
                background: $black;
              }
            }
            &.slick-active,
            &.slick-active button,
            &.slick-active button:before{
              width: 45px;
              top: -6px;
            }
          }
        }
      }
    }

    @media (max-width: 600px){
      padding-bottom: 40px;

      .bg-slide{
        img{
          width: 150%;
        }
      }
      .content-slide{
        .wrapper-slide{
          height: 82vw;
          min-height: 270px;

          .right{
            width: 70%;
            top: 10%;

            img{
              max-width: 35vw;
              max-height: 12vw;
            }

            p{
              max-width: 70%;
              font-size: 3.5vw;
              margin: 5vw 0;
            }

            .go-to-play{
              padding: 2.3vw 5vw;
              border-radius: 1vw;
              font-size: 2.6vw;
            }
          }
        }

        .slick-dots{
          bottom: -40px;
  
          li {
            height: 3px;
            width: 20px;
  
            button{
              height: 3px;
              width: 20px;
  
              &:before{
                height: 3px;
                width: 20px;
              }
            }
            &.slick-active,
            &.slick-active button,
            &.slick-active button:before{
              width: 30px;
              top: -4px;
            }
          }
        }
      }
    }
  }

  .section-recommended{
    .img-container{
      max-height: 380px;
      overflow: hidden;
    }
  }
}