@import "../../layout/mixins";
@import "../../layout/variables";

.current-balance-btns {
  display: flex;
  justify-content: space-between;
}
.buttons-block.buttons-block-v2 {
  max-width: 390px;
}
.buttons-block {
  
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start !important;
  gap: 10px;
  align-items: flex-start;
  
  .space-btn {
    justify-content: space-between;
  }
}
.buttons-block.buttons-block-v2 {
  button {
    // width: 28%;
    margin: 0 !important;
  }
}

.buttons-block {
  //display: flex;
  width: 100%;
  padding: 0px 10px;

  .MuiGrid-grid-sm-3 {
    max-width: 19%;
    flex-basis: 20%;
  }

  &.space-btn {
    justify-content: space-between;
  }

  &.flex-end {
    justify-content: flex-end;
  }

  button,
  a {
    color: $blue  !important;
    border: 1px solid $blue  !important;

    &:hover {
      background-color: $blue  !important;
      color: #fff !important;
      border: 1px solid $blue  !important;
    }

    &:disabled {
      background-color: #fff !important;
      color: #ACACAC !important;
      border: 1px solid #ACACAC !important;
    }
  }
}

.detail-top {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .name_logout {
    display: flex;
  }

  .qr_diag_btn {
    color: #0060FF !important;
    cursor: pointer;

    span {
      margin-right: 15px;
    }
  }
}

.qr_main_dialog {
  max-width: 375px;
  padding: 50px;
  position: relative;
  overflow: auto;

  span {
    position: absolute;
    top: 10px;
    right: 10px;

    &:hover {
      color: red;
    }
  }

  p {
    font-size: 14px;
    font-weight: 400;
    font-family: Roboto;
    text-align: center;
    max-width: 275px;
  }

  div {
    text-align: center !important;
    margin-top: 20px;
    padding: 25px;
    border: 5px solid #DADADA;
    position: relative;
    border-radius: 10px;

    img {
      width: 200px !important;
      height: 200px !important;
      position: relative;
      z-index: 1;
    }
  }

  div::before,
  div::after {
    content: "";
    display: block;
    position: absolute;
    background: #fff;
  }

  div::before {
    top: -10px;
    bottom: -10px;
    left: 25px;
    right: 25px;
  }

  div::after {
    left: -10px;
    right: -10px;
    top: 25px;
    bottom: 25px;
  }
}

.connct {
  max-width: 35%;
  margin: 0 auto;
  height: 550px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.connct p {
  font-size: 32px;
  font-family: Roboto;
  line-height: 1.56;
  text-align: center;
  color: #707070;
  font-weight: 700;
}

.connctpic {
  width: 34%;
  margin: 50px auto;
}

.connctlogo {
  width: 100%;
}

/*----------------------------------------------
0.3.1. buy button dropdown
display dropdown on hover when buy button
----------------------------------------------*/

.pop1 {
  background-color: transparent !important;
  border: none !important;
  max-width: 340px !important;
}

.pop1 .popover-arrow::after {
  display: none;
}

.pop1 .popover-arrow::before {
  display: none;

}

.buydrpdn::before {
  background-image: url("../../assets/images/icons/drpdntriangle.png");
  content: "";
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  width: 50px;
  height: 33px;
  left: 43%;
  top: -24px;
}

.buydrpdn {
  position: relative;
  width: 340px;
  padding: 15px 0px 30px 0px;
  border-radius: 10px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.4);
  background-color: #ffffff;
}

.drpheader {
  border-bottom: 1px solid #cccccc;
}

.drpheader h1 {
  font-family: Roboto;
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  color: #464646;
  margin-bottom: 15px;
}

.drpbtn1 a span img {
  width: 85% !important;
}

.drpbtn1 a {
  width: 70% !important;
  height: 51px !important;
  margin: 29px 50px 10px 50px !important;
  padding: 9px 50px 9px 50px !important;
  border-radius: 5px !important;
  background-color: #23af91 !important;
  border: none !important;
}

.drpbtn2 a span img {
  width: 85% !important;
}

.drpbtn2 a {
  width: 70% !important;
  height: 51px !important;
  margin: 0px 50px 0px 50px !important;
  padding: 9px 50px 9px 50px !important;
  border-radius: 5px !important;
  background-color: #de5959 !important;
  border: none !important;
}

.drpbdr {
  display: flex;
  max-width: 89%;
  margin: 20px auto;
}

.drpbdr .dot {
  font-size: 20px;
  color: #016ce9;
  font-weight: 700;
}

.drpor {
  margin: 0px 5px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  background-color: #eff6ff;
}

.drpor p {
  font-family: Roboto;
  font-size: 20px;
  color: #016ce9;
  line-height: 2;
}

.drpftr p {
  font-family: Roboto;
  font-size: 16px;
  text-align: center;
  color: #111;
}

.drpftr Button {
  width: 70% !important;
  height: 50px !important;
  margin: 17px 50px 0px 50px !important;
  padding: 0 0px 0 0px !important;
  border-radius: 5px !important;
  background-color: #016ce9 !important;
  font-family: Roboto !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  color: #fff !important;
  border: none !important;
}

.pledge_drpdn::before {
  background-image: url("../../assets/images/icons/drpdntriangle.png");
  content: "";
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  width: 50px;
  height: 33px;
  left: 43%;
  top: -24px;
}

.pledge_drpdn {
  position: relative;
  width: 340px;
  padding: 15px 0px 40px 0px;
  border-radius: 10px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.4);
  background-color: #ffffff;
}

.pledge_header {
  border-bottom: 1px solid #cccccc;
}

.pledge_body {
  padding-top: 15px;
}

.pledge_drpdn h1 {
  font-family: Roboto;
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  color: #464646;
  margin-bottom: 15px;
}

.pledge_btn .button {
  width: 80% !important;
  height: 51px !important;
  margin: 0px 34px !important;
  border-radius: 5px !important;
  background-color: $blue  !important;
  border: 1px solid $blue  !important;
  margin-top: 15px !important;
  font-size: 18px !important;
  color: #fff !important;

  &:hover {
    background-color: #3793FF !important;
    border: 1px solid #3793FF !important;
  }

  &:disabled {
    background-color: #80B6F4 !important;
    border: 1px solid #80B6F4 !important;
  }
}


@media screen and (max-width: 600px) {
  .drpftr p {
    font-size: 14px !important;
  }

  .drpbdr .dot {
    font-size: 18px !important;
    font-weight: 600;
  }

  .drpbdr {
    max-width: 93%;
  }

  .buydrpdn::before {
    left: 25%;
  }

  .buydrpdn {
    width: 285px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.5);
  }

  .drpbtn1 a {
    width: 80% !important;
    margin: 30px 28px 10px 28px !important;
  }

  .drpbtn2 a {
    width: 80% !important;
    margin: 0px 29px 0px 29px !important;
  }

  .drpftr Button {
    width: 235px !important;
    margin: 17px 25px 0 25px !important;
  }

  .pledge_drpdn::before {
    left: 25%;
  }

  .pledge_drpdn {
    width: 285px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.5);
  }

  .pledge_btn a {
    width: 80% !important;
    margin: 0px 29px 0px 29px !important;
  }
}

/*----------------------------------------------
            end buy button dropdown
      display dropdown on hover when buy button
----------------------------------------------*/
.coin-block {
  align-items: center;
  width: calc(100% - 390px);

  .block {
    display: flex;
    padding: $px-10;

    &.block-01 {
      padding: $px-10 $px-10 $px-10 0;
    }

    &.block-02 {
      padding: $px-8 $px-10;
      flex-direction: column;
      margin-left: 60px;
      background-color: darken($white, 3%);
    }

    .row {
      display: flex;
      align-items: center;

      +.row {
        margin-top: 4px;
      }

      .label {
        font-size: $px-18;
        opacity: .9;
        color: lighten($black, 15%);
        margin: 0;
      }

      .amount {
        text-align: right;
        margin-left: auto;
        color: lighten($black, 13%);
        line-height: 1.2;

        span {
          font-size: $px-22;
          font-weight: 700;
        }
      }
    }
  }

  .coin-name {
    display: inline-flex;
    align-items: center;
    width: 234px;
    flex-wrap: wrap;
    .coin-logo {
      padding: $px-8 $px-12;
      border-radius: 999px;
      background: darken($white, $amount: 10%);
      margin-right: $px-20;

      img {
        vertical-align: middle;
        width: 26px;
        height: 34px;
      }
    }

    .coin-label {
      font-size: $px-22;
      font-weight: 400;
      color: lighten($black, 13%);
      font-weight: 500;

      .info-btn {
        padding: 5px;
        position: relative;
        top: -2px;

        svg {
          width: 18px;
          height: 18px;
          fill: darken($white, 30%)
        }

        &.open svg {
          fill: $blue;
        }
      }

      .MuiTooltip-popper {
        .MuiTooltip-tooltip {
          text-align: left;
          margin: 0 4px;
          padding: 0;
          background: none;

          .tooltip-content {
            padding: 18px 20px;
            background-color: $white;
            box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
            color: $text-shade-2;
            border-radius: 4px;
          }

          h5 {
            margin: 0;
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 10px;
          }

          p {
            font-size: 14px;
            font-weight: 300;
            line-height: 1.4;
            margin-top: 2px;

            span {
              font-weight: 400;
              color: $text-shade-1;
            }
          }

          .MuiTooltip-arrow {
            color: $white;
          }
        }
      }
    }
  }

  .coin-value {
    text-align: left;
    // margin-top: 2%;
    margin-left: 40px;
    color: lighten($black, 13%);
    font-weight: 700;
    font-size: 18px;
    width: 183px;
    span {
      font-size: 18px;
      font-weight: 700;
    }

    .convertion {
      display: block;
      font-size: $px-16;
      font-weight: 400;
      color: darken($text-shade-4, 10%);
      text-align: start;
    }
  }

  @media (max-width: 600px) {

    .block {
      padding: $px-6 $px-10;

      &.block-01 {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
      }

      &.block-02 {
        padding: $px-4 $px-10;
        margin-left: 32px;
      }

      .row {
        .label {
          font-size: $px-12;
        }

        .amount {
          font-size: 11px;

          span {
            font-size: $px-14;
          }
        }
      }
    }

    .coin-name {
      .coin-logo {
        padding: $px-4 $px-8;
        margin-right: $px-10;

        img {
          width: 16px;
          height: 22px;
        }
      }

      .coin-label {
        font-size: $px-14;
      }
    }

    .coin-value {
      font-size: 11px;

      span {
        font-size: $px-14;
      }

      .convertion {
        font-size: 11px;
      }
    }
  }
}

.user-content {
  .desktop-view {
    padding: 0 0 0;

    .container {
      display: flex;
    }

    .sidebar {
      h2 {
        font-size: $px-20;
        color: lighten($black, 13%);
        padding: $px-24 $px-18 $px-20;
        margin: 0;
      }

      .menu-list {
        border: 1px solid darken($white, 13%);
        min-width: 280px;
        margin-right: $px-18;
        border-radius: $px-6;
        padding: 0;
        overflow: hidden;

        .list-item {
          border-bottom: 1px solid darken($white, 13%);
          margin: 0;

          &:last-child {
            border-bottom: none;
          }

          .MuiListItem-button {
            padding: $px-12 $px-12 $px-12 $px-24;
            color: lighten($text-shade-2, 8%);

            .MuiListItemIcon-root {
              min-width: auto;
              margin-right: $px-12;
            }
          }

          svg {
            width: 24px;
            fill: lighten($text-shade-2, 12%);
          }

          &.active {
            background: #f3f9ff;

            >.MuiListItem-button {
              color: $blue;
              position: relative;

              &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 5px;
                background: $blue;
              }
            }

            svg {
              fill: $blue;
            }
          }
        }

        .collapsible-item {
          .nested-list {
            padding-bottom: $px-10;

            .MuiListItem-button {
              padding: $px-4 $px-4 $px-4 $px-50;
              font-size: $px-14;

              .MuiListItemText-root {
                margin: 0;

                .MuiTypography-body1 {
                  font-size: $px-14;
                }
              }

              &.active-nested {
                background: lighten($blue, 46%);
              }
            }
          }
        }
      }
    }
    .user-sidebar-wrapper.user-sidebar-wrapper-v2 {
      display: flex;
      flex-wrap: wrap;
      gap: 48px;
      padding-left: 50px;
      justify-content: space-between;
      align-items: flex-start;
    }
    .content.content-v2 {
      margin-top: 40px;
      max-width: calc(100% - 500px);
    }
    .content {
      border: 1px solid darken($white, 13%);
      width: 100%;
      border-radius: $px-10;
     
      .pagination {
        margin: 32px 0px 18px;
        justify-content: center;

        button {
          &.Mui-selected {
            background-color: #016CE9 !important;
            border-color: #016CE9;
            color: #fff;

            &:hover {
              color: #fff !important;
            }
          }

          &:hover {
            color: #016CE9 !important;
            background-color: #fff;
            border-color: #016CE9 !important;
          }
        }

        ul {
          display: flex;
          justify-content: center;

          li {
            button {
              border-radius: 8px;
              font-size: 12px !important;
              border-color: #A6A6A6;
              color: #A6A6A6;
            }
          }
        }
      }
    }
  }

  .marginbot {
    margin-bottom: 15px;
  }


  .profile-content {
    padding: 0.625rem 3.125rem 0;

    .section {
      padding-top: $px-30;
      padding-bottom: $px-30;
      border-bottom: 1px solid darken($white, 13%);

      // .balance-history {
      //   // margin-bottom: 30px;
      // }

      .history-btn {
        color: #016CE9;
        border: 1px solid #016CE9;
        padding: 3px 17px;
        border-radius: 50px;
        font-size: 16px;

        svg {
          margin-right: 5px;
        }
      }

      .balance-history {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &:last-child {
        border-color: transparent;
      }

      .details {
        width: 100%;
      }

      .title {
        margin: 0;
        font-size: $px-24;
        color: lighten($black, 13%);
      }

      >.title {
        margin-bottom: $px-20;
      }

      .copy-btn {
        border-radius: 999px;
        background: $blue;
        color: $white;
        padding: 6px 12px 6px;
        font-size: $px-16;
        line-height: 1;
        font-weight: 500;

        svg {
          fill: $white;
          display: inline-block;
          width: $px-14;
          margin-right: $px-4;
        }
      }

      .logout-btn {
        border-radius: 6px;
        background: lighten($black, 44%);
        color: $white;
        padding: 6px 14px;
        text-transform: initial;
        font-size: $px-18;
        line-height: 1;
        font-weight: 500;
      }

      .balance-block+.balance-block {
        margin-top: $px-16;
      }

      .balance-block{
        border: 1px solid #E0E0E0;
        border-radius: 8px;
        padding: 16px 24px;
        flex-wrap: wrap;
      }

      .buttons-block {
        text-align: right;
        margin-top: $px-4;
        display: flex;
        flex-direction: row;
        justify-content: flex-end !important;
        margin-left: auto;

        button:disabled {
          color: rgba(0, 0, 0, 0.26);
          border: 1px solid rgba(0, 0, 0, 0.12);
        }

        .MuiButton-root {
          min-width: 110px;
          width: 110px;
          font-weight: 400;
          font-size: $px-16;
          line-height: 1.6;
          border-radius: 5px;
          color: $blue;
          border-color: $blue;
          font-weight: 500;
          margin-left: 5px;
        }
      }

      &.s1 {
        display: flex;
        padding-top: 0;
        padding-bottom: $px-18;

        .name {
          display: flex;
          align-items: center;
          margin-bottom: $px-12;

          .title {
            line-height: 1;
          }

          .logout-btn {
            margin-left: 10px;
            position: relative;
            bottom: 1px;
          }
        }

        .copy-btn {
          margin-bottom: 4px;
        }

        p {
          margin: 0;
          font-size: $px-18;

          >span {
            display: inline-block;
          }

          .detail-label {
            min-width: 130px;
            margin-right: $px-20;
          }

          .value {
            margin-right: $px-10;
            color: darken($text-shade-3, 7%);
          }
        }

        .img-container {
          display: flex;
          align-items: center;
          margin-right: 20px;
          width: 60px;
          position: relative;
          gap: 10px;

          div {
            width: 50px;
            height: 50px;
            background-color: #D9D9D9;
            border-radius: 50%;
            position: absolute;
            top: 0px;

            img {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .package-content {
    padding: $px-30;

    .empty-package {
      text-align: center;
      margin: 0;
    }
  }

  .mobile-view {
    >.MuiPaper-root {
      border-bottom: 6px solid darken($white, 10%);

      .MuiTabs-root {
        padding: 6px 7px !important;

        .MuiButtonBase-root {
          text-transform: initial;
          font-size: $px-16;

          &.Mui-selected {
            color: $blue;
          }
        }

        .MuiTabs-indicator {
          background-color: $blue;
        }
      }
    }

    .profile-content {
      background: $white;
      padding: 0;
      padding-top: $px-80;

      .section {
        padding-left: $px-24;
        padding-right: $px-24;
        border-bottom: 6px solid darken($white, 10%);

        &.s1 {
          .img-container {
            width: 116px;
          }
        }
      }
    }

    .package-content {
      padding: $px-20 $px-24 $px-50;
    }

    @media (max-width: 600px) {
      .detail-top {
        display: block !important;
      }

      .name_logout {
        display: block !important;
      }

      >.MuiPaper-root {
        .MuiTabs-root {
          min-height: 40px;

          .MuiButtonBase-root {
            font-size: $px-14;
            min-height: 40px;
          }
        }
      }

      .profile-content {
        padding-top: $px-60;

        .section {
          padding: $px-16 $px-12;

          .details {
            p {
              display: flex;
              align-items: center;

              button {
                margin-left: auto;
              }
            }
          }

          .title {
            font-size: $px-16;
          }

          >.title {
            margin-bottom: $px-14;
          }

          .copy-btn {
            padding: 4px 8px 3px;
            font-size: $px-10;
            min-width: 50px;

            svg {
              width: $px-12;
              margin-right: $px-4;
            }
          }

          &.s1 {
            padding-bottom: $px-14;
            padding-top: 0;

            .name {
              margin-bottom: $px-4;

              .title {
                font-size: $px-14;
              }

              .logout-btn {
                font-size: 11px;
                bottom: 2px;
                padding: 6px 0;
                min-width: 66px;
              }
            }

            p {
              margin-bottom: 0;
              font-size: $px-12;

              &:last-child {
                margin-bottom: 0;
              }

              .detail-label {
                min-width: 82px;
                margin-right: $px-10;
              }

              .value {
                margin-right: $px-6;
                min-width: 82px;
              }
            }

            .img-container {
              width: 80px;
              margin-right: $px-12;
            }
          }

          &:last-child {
            border: none;
          }

          .balance-block+.balance-block {
            margin-top: $px-12;
          }

          .buttons-block {
            margin-top: 0;

            .MuiButton-root {
              font-size: $px-12;
              min-width: 0;
              flex: 1;
              margin: 3px;

              &:first-child {
                margin-left: 0;
              }

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }

    @media (max-width: 400px) {
      .profile-content .section.s1 .img-container {
        width: auto;
      }
    }
  }
}

.dappxPR-modal.wallet-network-error-modal {
  .mdl-body {
    padding: 50px 55px;
  }

  h3 {
    margin: 20px auto;
  }
}

.beta-chip {
  background-color: #898989;
  color: #fff;
  border-radius: 5px;
  font-size: 12px;
  padding: 4px 6px;

  @media (max-width: 960px) {
    font-size: 10px;
    padding: 2px 3px;
  }
}

.transaction-preview {
  .block {
    display: flex;
    flex-direction: column;

    .preview-header,
    .preview-body {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      >div {
        text-align: right;
        padding: 10px 20px;
        font-weight: 600;
        font-size: 20px;
        vertical-align: middle;
        line-height: 1;
      }

      &.column-2 {
        >div:not(:first-child) {
          flex-basis: 37.5%;
        }

        >div:first-child {
          flex-basis: 25%;
          text-align: left;
        }
      }

      &.column-3 {
        >div:not(:first-child) {
          flex-basis: 28.33%;
        }

        >div:first-child {
          flex-basis: 15%;
          text-align: left;
        }
      }
    }

    .preview-header {
      border-bottom: 1px solid #d6d6d6;

      >div {
        padding-top: 12px;
      }
    }

    .preview-body {
      >div {
        padding-top: 12px;
      }

      .amount {
        color: #212121;
        font-size: 1.375rem;
        font-weight: 700;
        line-height: 1.2;
        white-space: nowrap;

        span {
          font-size: 1rem;
          font-weight: 400;
        }
      }

      .currency {
        color: #016ce9;
        font-weight: 400;
        font-size: 16px;
      }
    }
  }
}

.token-details {
  padding: 20px 32px;
  background-color: #F7F7F7;
  border-radius: .5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 100%;

  .details-text-container {
    display: flex;
    flex-direction: column;
    align-content: center;

    .amount {
      line-height: 1;
    }
  }

  .details-icon {
    height: 51px;
    width: auto;
    filter: invert(73%) sepia(25%) saturate(534%) hue-rotate(180deg) brightness(103%) contrast(102%);
    z-index: 1;

    @media (max-width: 960px) {
      height: 35px;
    }
  }



  img {
    width: 20px;
    margin-left: 10px;
    margin-bottom: 5px;
  }

  h6 {
    display: flex;
    align-items: center;
    line-height: 1;
  }

  @media (max-width: 960px) {
    padding: 1.5rem .75rem;
  }

  @media (max-width: 600px) {
    padding: .5rem;
  }
}




.history-drawer {

  // height: 100%;
  h2 {
    text-align: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #111111;
    border-bottom: 1px solid #DDDDDD;
    margin: 0;
    padding: 20px 0;
  }

  .history-wrapper {
    padding: 0 0 20px 0;
    height: 100%;
  }

  .end-of {
    text-align: center;
    margin-bottom: 82px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    color: #9F9F9F;
  }

  .close-btn {
    background: #E1E1E1;
    border-radius: 5px;
    width: calc(100% - 48px);
    border: none;
    padding: 14px 0;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #626262;
    margin: 0 24px;
    &:hover {
      background: #E1E1E1;
      color: #626262;
      font-weight: 700;
    }
  }

  .history-item-area {
    height: calc(100vh - 151px);
    overflow-y: auto;
    padding: 20px 20px 0;

    .history-item {
      border-bottom: 1px solid #DDDDDD;
      margin-bottom: 16px;
      padding-bottom: 16px;

      .history-item-top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;

        p {

          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #626262;
        }

        span {

          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;

          color: #016CE9;
        }
      }

      .history-item-middle {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .history-item-content {
          display: flex;
          align-items: center;
        }

        .history-item-thumb {
          width: 50px;
          height: 50px;
          background: #DDDDDD;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .history-item-typo {
          margin-left: 14px;

          h5 {
            margin: 0 0 0 0;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            color: #111111;
          }

          span {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;

            color: #111111;

          }


        }

        h4.amount {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 700;
          font-size: 22px;
          line-height: 24px;

          color: #111111;
          margin: 0;

          svg {
            margin-right: 5px;
          }

          span {
            font-size: 16px;
            font-weight: 400;
          }
        }
      }
    }
  }
}
.balance-history .history-btn {
  color: #016CE9;
  border: 1px solid #016CE9;
  padding: 3px 17px;
  border-radius: 50px;
  font-size: 16px;

  svg {
    margin-right: 5px;
  }
  &:hover {
    color: #016CE9;
  }
}

.balance-transaction-history {
  display: flex;;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.user-sidebar-wrapper {
  .EZDrawer__overlay {
    display: none !important;
  }

  .EZDrawer .EZDrawer__container {
    box-shadow: none;
    border-left: 1px solid #E0E0E0;
  }
}

.content-wrapper-v2 {
  max-width: 900px;
  margin: 40px auto;

  @media (max-width: 1440px) {
    max-width: 700px;
  }
}

.content.paypal.marginbot {
  border: none;
}

.content-wrapper-v2.paypal {
  width: 100%;
  max-width: calc(100% - 400px);
  margin: 0;
}
@media (max-width: 1550px) {
  .coin-block {
    width: calc(100% - 260px);
  }
  .buttons-block.buttons-block-v2 {
    max-width: 260px;
}
}

@media (max-width: 1440px) {
  .coin-block .coin-name {
    width: 150px;
  }
  .coin-block .coin-value {
    margin-left: 10px;
    width: 140px;
  }
  .coin-block {
    width: calc(100% - 120px);
  }
  .buttons-block.buttons-block-v2 {
    max-width: 120px;
}
}
