.maintenance-wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -65px;

  @media (max-width: 600px) {
    padding: 0 2rem;
  }

  .maintenance-container {
    width: 587px;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    text-align: center;
    font-weight: bold;
    font-size: 46px;
    line-height: 1;
    color: #1b3995;
  }

  .content {
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 3rem;
    font-size: 18px;
    line-height: 28px;
    color: #707070;
    width: 400px;
  }


  .dappstore-logo {
    height: 48px;
  }
}
