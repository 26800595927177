@import "../../layout/mixins";
@import "../../layout/variables";

.dappxPR-modal {
  overflow: auto;
  position: relative;

  .paper-wrapper {
    height: 100%;
    min-height: 710px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    pointer-events: none;
  }

  .paper {
    pointer-events: auto;
    background-color: $white;
    border-radius: $px-10;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    width: 500px;
    text-align: center;
    box-sizing: border-box;

    .mdl-header {
      padding: $px-20;
      border-bottom: 1px solid lighten($black, 80%);
      position: relative;

      h2 {
        margin: 0;
        font-size: $px-24;
        color: $text-shade-2;
        font-weight: 500;
      }

      .close-btn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        svg {
          width: $px-24;
          height: $px-24;
          fill: $text-shade-3;
        }
      }
    }

    .mdl-body {
      padding: $px-40;
    }
  }

  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.7);
  }

  @include custom-error;

  &.connect-wallet-modal {
    .paper {
      width: 920px;
    }
    .mdl-body {
      padding-bottom: $px-26;
      .btn-group {
        display: flex;
        flex-direction: row;
        position: relative;
      }
      .btn-container {
        flex-direction: column;
        width: 100%;
        border: 1px solid lighten($black, 88%);
        border-radius: $px-10;
        overflow: hidden;
        transition: box-shadow 0.2s ease-in-out;
        background: darken($white, 3%);
        transition: background .3s ease-in-out, opacity .3s ease-in-out, border .3s ease-in-out, filter .3s ease-in-out;

        button {
          width: 100%;
          transition: opacity .3s ease-in-out;

          &.metamask{
            height: 100%;
          }
        }

        .img-container {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          min-height: 330px;

          .supported-list {
            @include horizontal-list;
            margin-top: $px-24;

            li {
              border-radius: 999px;
              background: darken($white, 10%);
              padding: 5px;
              margin-right: $px-12;

              img {
                vertical-align: middle;
              }

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }

        .note{
          background: $blue-2;
          // background: #FFFFFF;
          width: 100%;
          padding: $px-10 15px;
          color: #4F4F4F !important;
          .note-list {
            @include reset-list;
            display: inline-block;
            cursor: auto;
            color: $white;
            margin-bottom: 0;
            box-sizing: border-box;
            text-align: left;

            li{
              margin: 2;
              font-family: 'Roboto';
              font-style: normal;
              font-weight: 400;
              font-size: 13px;
              line-height: 20px;
              // background-image: url('../../assets/images/bullet-tick.png');
              background-image: url('../../assets/images/checkmark.svg');
              background-repeat: no-repeat;
              padding-left: 30px;
            }
  
            a {
              color: $sky-blue-3;
              font-weight: 500;
              text-decoration: none;
  
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }

        &:hover{
          border: 1px solid lighten($black, 80%);
          background: darken($white, 8%);
        }

        &.disabled,
        &.disabled:hover,
        &.loading,
        &.loading:hover {
          opacity: 0.6;
          filter: grayscale(100%);
          border: 1px solid lighten($black, 88%);
          background: darken($white, 3%);
          button {
            opacity: 0.5;
          }
        }

        &.disabled {
          background: darken($white, 3%);
          cursor: no-drop;
        }
      }
      .btn-container + .btn-container {
        margin-left: $px-8;
      }

      .notice {
        margin-top: $px-22;
        span,
        a {
          color: $blue-2;
          font-weight: 500;
          text-decoration: none;
        }
        a:hover {
          text-decoration: underline;
        }
      }

      .error{
        margin-top: 8px;
      }
    }

    &.is-mobile{
      .paper {
        width: 580px;
      }
      .mdl-body {
        padding-top: $px-30;
        .btn-group .powered-outside{
          display: none;
        }
        .btn-group.disabled .powered-outside{
          display: flex;
        }
        .powered-outside{
          display: none;
          position: absolute;
          top: 2px;
          right: -2px;
          z-index: 1;
        }
        .powered-outside,
        .powered{
          display: flex;
          align-items: center;
          width: calc( 100% - 32px );
          margin: 14px 16px 20px;
          font-weight: 300;
          font-size: $px-14;

          span{
            display: block;
            margin-left: auto;
            margin-right: $px-10;
          }

          img{
            max-width: 120px;
          }
        }
        .btn-container{
          background: $white;
          
          .img-container {
            min-height: auto;
            width: 100%;
            margin-bottom: $px-30;

            .img-fluid{
              max-width: 80%;
            }
          }

          .note{
            color: $white;
          }

          &:hover{
            background: darken($white, 3%);
          }
          &.disabled,
          &.disabled:hover,
          &.loading,
          &.loading:hover {
            border: 2px solid lighten($black, 30%);
            opacity: 0.6;
            background: darken($white, 7%);

            button {
              opacity: 0.9;
            }

            .powered{
              opacity: 0;
            }
          }
        }
      }
    }
  }

  &.notice-modal{
    .paper {
      width: 660px;
    }
    .mdl-body {
      padding-top: $px-30;
      .desc{
        margin-top: 0;
        color: $text-shade-3;
        margin-bottom: $px-30;
        line-height: 1.4;
      }

      .img-container{
        margin-bottom: $px-32;
      }

      .install-btn{
        font-weight: 400;
        padding: $px-12;
        font-size: $px-16;
        letter-spacing: 1px;
      }

      .get-it-btn{
        margin: 5px;
        .content-wrapper{
          min-width: 140px;
          background: $blue-2;
          padding: $px-12 $px-16;
          border-radius: $px-4;
        }

        .icon {
          width: $px-28;
          height: $px-34;
          margin-right: $px-16;
          min-width: $px-16;
        }

        .text {
          div.text-small {
            font-size: $px-12;
          }
          div.text-big {
            font-size: $px-18;
          }
        }

        @media (max-width: 1111px) and (min-width: 960px){
          .content-wrapper {
            height: auto;
          }
        }

        @media (max-width: 959px){
          &.windows-btn {
            display: inline-flex;
          }
          .bg-icon{
            fill: $blue-2;
            width: auto;
          }
          .icon {
            width: $px-34;
            height: $px-34;
            margin-right: 0;
          }
          .content-wrapper {
            min-width: auto;
            background: transparent;
            position: relative;
            z-index: 2;
            padding: $px-18;
          }
        }

        @media (max-width: 599px){
          .bg-icon {
            width: auto;
          }
        }
      }
    }

    @media (max-width: 959px){
      .paper {
        width: 500px;
      }
    }
  }

  &.create-account-modal,
  &.link-account-modal {
    .mdl-body {
      padding: $px-36 $px-40;
      .desc {
        color: $text-shade-3;
        font-weight: 300;
        font-size: $px-16;
        text-align: left;
        margin: 0;
        line-height: 1.4;
        margin-bottom: $px-26;

        &.desc-2 {
          margin-bottom: 0;

          a {
            color: $sky-blue;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .img-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: $px-28;

        svg {
          width: 114px;
          fill: $light-purple;
        }
      }

      .form-input {
        margin-bottom: $px-10;
        .MuiOutlinedInput-input {
          padding: $px-14 $px-20;
        }
        .MuiOutlinedInput-notchedOutline {
          border-width: 2px;
        }
      }

      .btn-submit {
        position: relative;
        padding: $px-10;
        margin-bottom: $px-10;
        border-radius: 16px;
        background-color: #016CE9;
        color: #FFFFFF;
        font-weight: 400;
        font-size: 14px;

        .text {
          position: relative;
          font-size: $px-16;
          right: 0;
          transition: right 0.4s ease-in-out;
          font-weight: 400;
        }

        &.Mui-disabled {
          color: $white;
          background: lighten($black, 70%);
          .text {
            opacity: 0.8;
          }

          &.incorrect-email,
          &.error-ongoing {
            .text {
              right: 0;
            }
          }
        }
      }
    }
  }

  &.link-account-modal {
    .mdl-body {
      .desc {
        margin-bottom: $px-38;
        span {
          color: $blue-2;
          font-weight: 400;
        }
      }
      .img-container {
        margin-bottom: $px-50;
      }
      .btn-submit {
        margin-bottom: $px-2;
      }
      .alert-container{
        position: relative;
        width: 100%;
        > .MuiAlert-root{
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
        }
      }
      .success,
      .error {
        margin-bottom: $px-8;
      }
    }
  }
  &.info-connect-modal,
  &.error-connect-modal {
    .paper{
      width: 440px;
      border-radius: $px-20;
    }
    .mdl-body{
      padding: $px-60 90px;
    }
    h3{
      font-size: 26px;
      margin: 6px auto;
    }
    .desc{
      margin-top: 10px;
      margin-bottom: 24px;
      line-height: 1.6;
      font-weight: 300;
    }
    .icon{
      width: 78px;
      height: auto;
      fill: $red; 

      &.info{
        width: 68px;
        fill: $blue;
      }
    }
    .btn-ok{
      padding: 10px;
      min-width: 145px;
      border-radius: $px-8;
      background-color: lighten($blue, 2%);
    }
  }

  &.info-connect-modal{
    .mdl-body{
      padding: $px-60;
    }
  }

  @media (max-width: 959px) {
    &.connect-wallet-modal {
      .paper {
        width: calc(100% - 28px);
      }
    }
  }

  @media (max-width: 840px) {
    &.connect-wallet-modal {
      .paper {
        width: 500px;
      }
      .mdl-body{
        .btn-group {
          flex-direction: column;
        }
        .btn-container {
          .img-container {
            min-height: 210px;

            img {
              max-width: 110px;
            }

            .supported-list{
              li{
                margin-right: 8px;
                img{
                  max-width: 30px;
                }
              }
            }
          }
          button.metamask{
            .img-container{
              min-height: 200px;
            }
          }
        }
        .btn-container + .btn-container {
          margin-left: 0;
          margin-top: 10px;
        }
      }
    }
  }

  @media (max-width: 600px) {
    .paper {
      width: calc(100% - 28px);
      margin: $px-14;

      .mdl-header {
        padding: $px-16;
        h2 {
          font-size: $px-16;
        }
        .close-btn {
          right: $px-4;
          svg {
            width: $px-16;
            height: $px-16;
          }
        }
      }

      .mdl-body {
        padding: $px-26;
      }
    }

    .MuiAlert-root {
      font-size: 12px;
      margin-bottom: 4px;

      .icon {
        width: 16px;
        height: 16px;
        margin-right: 6px;
        min-width: $px-16;
      }

      .MuiAlert-icon {
        padding: 3px 0;
        font-size: 18px;
        margin-right: 8px;
      }

      .MuiAlert-message {
        padding: 4px 0;
      }
    }

    &.connect-wallet-modal {
      .paper-wrapper {
        min-height: 640px;
      }
      .paper{
        max-width: 375px;
      }
      .mdl-body {
        padding: $px-20;
        .btn-container {
          .img-container {
            min-height: 200px;

            img {
              max-width: 100px;
            }

            .supported-list{
              li{
                img{
                  max-width: 24px;
                }
              }
            }
          }

          .note{
            .note-list {
              font-size: $px-10;
            }
          }
        }

        .notice{
          font-size: $px-12;
          margin-top: 12px;
        }

        .error {
          margin-bottom: 0;
        }
      }
    }

    &.connect-wallet-modal.is-mobile {
      .paper {
        width: calc(100% - 28px);
      }
      .mdl-body {
        padding-bottom: $px-20;

        .powered-outside,
        .powered{
          width: calc( 100% - 20px );
          margin: 10px 10px 26px;
          font-size: $px-12;

          img{
            max-width: 100px;
          }
        }
        .btn-container{
          .note{
            font-size: 12px;
          }
        }
        .notice{
          font-size: $px-12;
          line-height: 1.43;
          margin-top: 18px;
        }
        .error {
          margin-bottom: 0;
        }
      }
    }

    &.notice-modal{
      .mdl-body{
        .desc{
          font-size: $px-14;
          margin-bottom: $px-26;
        }
        .img-container {
          margin-bottom: $px-26;
        }
      }
    }

    &.create-account-modal,
    &.link-account-modal {
      .paper-wrapper {
        min-height: 500px;
      }
      .paper{
        max-width: 375px;
      }
      .mdl-body {
        padding: $px-20 $px-26 $px-26;
        .desc {
          font-size: $px-12;
          margin-bottom: $px-14;
        }

        .img-container {
          margin-bottom: $px-16;

          svg {
            width: 76px;
          }
        }

        .form-input {
          margin-bottom: $px-8;
          font-size: $px-12;
          .MuiOutlinedInput-input {
            padding: $px-14 $px-16;
          }
          .MuiOutlinedInput-notchedOutline {
            border-width: 1px;
          }
        }

        .btn-submit {
          padding: $px-8;
          margin-bottom: $px-18;

          .text {
            font-size: $px-14;
          }
        }
      }
    }

    &.link-account-modal {
      .mdl-body {
        .desc {
          margin-bottom: $px-24;
        }
        .img-container {
          margin-bottom: $px-28;
        }
        .btn-submit {
          margin-bottom: 0;
        }
      }
    }

    &.info-connect-modal,
    &.error-connect-modal {
      .paper-wrapper {
        min-height: 300px;
      }
      .paper{
        width: calc(100% - 28px);
      }
      .mdl-body{
        padding: $px-30;
      }
      h3{
        font-size: 20px;
      }
      .desc{
        font-size: $px-14;
        line-height: 1.43;
      }
      .icon,
      .icon.info{
        width: 54px;
      }
      .btn-ok{
        padding: 8px;
        min-width: 100px;
      }
    }
  }
}


.dappxPR-modal.connect-wallet-modal .paper.restricted {
  width: 500px;
  .mdl-body {
    padding: 30px 20px 30px;
}
  .mdl-header {
    border: none;
    padding: 0 0;
    position: relative;
  }
  // .mdl-body {
  //   padding-top: 0;
  // }
  .mdl-header .close-btn svg {
    width: 15px;
  }
  button.MuiButtonBase-root.MuiIconButton-root.close-btn {
    top: 23px;
    right: 10px;
  }

  h5 {
    font-weight: 400;
    font-size: 16px;
    margin-top: 0;
  }
}